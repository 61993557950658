import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CollectionItem } from "./CollectionItem";
import { getRarity } from "../../helper/digitToText";
import { Button } from "../general/Button";
import { usePirateStore } from "../../store/usePirateStore";

export function ListCollectionItem({ item, onBuy, coins = 0, owned }) {
  const canAfford = item && item.price <= coins && onBuy;
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  function onItemClick(item) {
    if (!owned) {
      return;
    }
    setPrevRoute(location);
    item ? navigate("/item/" + item.id) : navigate("/all_items");
  }

  return (
    <div className="flex flex-row justify-between w-full " key={item.id}>
      <div className="flex flex-row gap-2 ">
        <CollectionItem item={item} owned={owned} onClick={onItemClick} />
        <div className="flex flex-col content-left text-xs ">
          <div className="headerFont text-left ">{item.name}</div>
          <div className="flex flex-wrap ">
            Superpower:{" "}
            <div className="text-accent pl-2">{item.superpower}</div>
          </div>
          <div className="flex flex-wrap ">
            Rarität:{" "}
            <div className="text-accent pl-2">{getRarity(item.rarity)}</div>
          </div>
          <div className="flex flex-wrap ">
            Preis: <div className="text-accent pl-2"> {item.price}</div>
          </div>
        </div>
      </div>
      {!owned && (
        <div className=" flex flex-col  place-content-end  ">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onBuy(item.id, canAfford);
            }}
            disabled={!canAfford}
            classes=" p-2 text-xs "
            small={true}
          >
            kaufen
          </Button>
        </div>
      )}
    </div>
  );
}
