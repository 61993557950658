import { HarbourBaseView } from "./HarbourBaseView";
import text from "../texts/allTexts.json";
import TitlePlusContent from "../general/TitlePlusContent";
import { useState } from "react";

export function HelpView() {
  const [openAnswer, setOpenAnswer] = useState(undefined);

  return (
    <HarbourBaseView title={text.harbour.help}>
      <TitlePlusContent
        activePart={5}
        title={text.harbour.helpQuestion5Title}
        content={text.harbour.helpQuestion5Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        activePart={6}
        title={text.harbour.helpQuestion6Title}
        content={text.harbour.helpQuestion6Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        activePart={1}
        title={text.harbour.helpQuestion1Title}
        content={text.harbour.helpQuestion1Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        activePart={2}
        title={text.harbour.helpQuestion2Title}
        content={text.harbour.helpQuestion2Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        activePart={3}
        title={text.harbour.helpQuestion3Title}
        content={text.harbour.helpQuestion3Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        activePart={4}
        title={text.harbour.helpQuestion4Title}
        content={text.harbour.helpQuestion4Text}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
    </HarbourBaseView>
  );
}
