import { Button } from "../general/Button";
import { ScreenWithButton } from "../general/ScreenWithButton";
import text from "../texts/allTexts.json";

export function PirateCheck({ onSubmit, onBack }) {
  return (
    <ScreenWithButton
      title={text.onboarding.pirateCheckTitle}
      subtitle={text.onboarding.pirateCheckSubTitle}
      fullWidthContent=" -mx-5"
      back={true}
      onClose={onBack}
      mode="brightBack"
    >
      <div className=" h-full">
        <div className="pt-16">
          <Button
            wide={true}
            onClick={() => {
              onSubmit("new");
            }}
          >
            {text.onboarding.pirateCheckNew}
          </Button>
        </div>
        <div className="pt-8">
          <Button
            wide={true}
            onClick={() => {
              onSubmit("existing");
            }}
          >
            {text.onboarding.pirateCheckExisting}
          </Button>
        </div>
      </div>
    </ScreenWithButton>
  );
}
