import { MAX_LENGTH_USER_NAME } from "../../Settings";
import { ScreenWithButton } from "../general/ScreenWithButton";
import TextField from "../general/TextField";
import { WaitingSpinner } from "../general/WaitingSpinner";
import text from "../texts/allTexts.json";

export function DiplomSetName({
  level,
  name = "",
  mail = "",
  onClickSend,
  setName,
  onClose,
  setStage,
  sending,
  levelimg,
}) {
  return (
    <ScreenWithButton
      buttonText={text.diplom.buttonSend}
      onButtonClick={() => {
        mail === "" ? setStage(2) : onClickSend();
      }}
      buttonDisabled={name === ""}
      title={text.diplom.titleSend}
      onClose={onClose}
      buttonReplacement={sending ? <WaitingSpinner /> : undefined}
    >
      <div className="h-full">
        {sending ? (
          <div>{text.diplom.textWaiting}</div>
        ) : (
          <>
            <div className="pb-4">{text.diplom.textFor}</div>
            <TextField
              value={name}
              onChange={(v) => {
                setName(v);
              }}
              styleOnBlur=" headerFont text-2xl text-accent bg-transparent border-none text-center h-10 "
              displayIcons={true}
              id="diplom-name"
              editIconClassname="text-xl"
              width="w-2/3"
              maxLength={MAX_LENGTH_USER_NAME}
            />
            <div className="headerFont my-6 mx-10 font-bold text-2xl">
              {text.diplom.success} {level}. {text.diplom.level}
            </div>
          </>
        )}
        <img
          className="pt-4 max-h-full object-scale-down place-self-center"
          alt="seaanimal "
          src={levelimg}
        />
      </div>
    </ScreenWithButton>
  );
}
