import { query, where } from "firebase/firestore/lite";
import { getDocsForQuery, itemsCombinationsRef } from "./firebase";

export async function getItemsCombinationsForFigure(figureName) {
  return await getDocsForQuery(
    query(itemsCombinationsRef, where("figure", "==", figureName))
  );
}

export async function getAllItemsCombinations() {
  return await getDocsForQuery(query(itemsCombinationsRef));
}
