import { useLocation, useNavigate } from "react-router-dom";
import { usePirateStore } from "../store/usePirateStore";
import text from "../components/texts/allTexts.json";

export function CoinsView({ coins = 0, clickable = true }) {
  const newItem = usePirateStore((state) => state.newItem);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const animate = newItem?.id === "coins";
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col place-items-end gap-1 pt-2"
      onClick={() => {
        if (!clickable) return;
        setPrevRoute(location);
        navigate("/all_items");
      }}
    >
      <img
        className={animate ? " w-14 animate-pulse" : " w-14"}
        src="/app_icons/coins.svg"
        alt="coins"
      />
      <div className="headerFont text-sm font-normal text-accent">
        {coins} {text.main.coins}
      </div>
    </div>
  );
}
