import { useState } from "react";
import { checkCode } from "../../services/code";
import { ScreenWithButton } from "../general/ScreenWithButton";
import TextField from "../general/TextField";
import { ForgotCodeView } from "./ForgotCodeView";
import log from "../../helper/Logger";
import { WaitingSpinner } from "../general/WaitingSpinner";
import text from "../texts/allTexts.json";

export function LoginView({ onCodeEntered, onBack }) {
  const [code, setCode] = useState("");
  const [forgotCode, setForgotCode] = useState("");
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onCodeSubmit = async () => {
    setCheckInProgress(true);
    let isValidCode = false;
    if (code) {
      const res = await checkCode(code);
      log.debug("result from checkCode: ", res);
      if (res) isValidCode = true;
    }

    setCheckInProgress(false);
    if (isValidCode) {
      onCodeEntered(code);
    } else {
      log.warn("not valid code");
      setErrorMessage(text.error.invalidCode);
      setCode("");
    }
  };

  return forgotCode ? (
    <ForgotCodeView
      setForgotCode={setForgotCode}
      onBack={() => setForgotCode("")}
    />
  ) : (
    <ScreenWithButton
      buttonText={text.onboarding.existingCodeButtonNext}
      onButtonClick={onCodeSubmit}
      buttonDisabled={!(code && code.length === 7) || checkInProgress}
      title={text.onboarding.existingCodeTitle}
      back={true}
      onClose={onBack}
      mode="brightBack"
    >
      {checkInProgress ? (
        <div className="relative h-full w-full">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
            <WaitingSpinner />
          </div>
          <div className="text-center p-8">
            {text.onboarding.existingCodeWaiting}
          </div>
        </div>
      ) : (
        <div className="h-full">
          <div>
            <div className="pt-8">{text.onboarding.existingCodeText}</div>
            <div className="flex flex-row gap-2 place-content-center py-8">
              <TextField
                value={code}
                onChange={(v) => {
                  setErrorMessage("");
                  setCode(v.toUpperCase());
                }}
                id="user-code"
                errorMessage={errorMessage}
              />
            </div>
          </div>
          <div
            className="font-bold mt-12 underline"
            onClick={() => {
              setErrorMessage("");
              setForgotCode(true);
            }}
          >
            {text.onboarding.existingCodeForgot}
          </div>
        </div>
      )}
    </ScreenWithButton>
  );
}
