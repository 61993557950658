import { getDocsForQuery, placesRef } from './firebase'
import { where, query, documentId } from 'firebase/firestore/lite'

export async function getPlace (placeId) {
  const places = await getPlaces([placeId])
  if (places.length) {
    return places[0]
  }
  return undefined
}

export async function getPlaces (ids) {
  return await getDocsForQuery(query(placesRef, where(documentId(), 'in', ids)))
}

export async function getAllPlaces () {
  return await getDocsForQuery(query(placesRef, where('disabled', '!=', true)))
}
