import { IntroHelpItems } from "../start/IntroHelpItems";
import { HarbourBaseView } from "./HarbourBaseView";
import text from "../texts/allTexts.json";

export function IntroView() {
  return (
    <HarbourBaseView title={text.harbour.intro}>
      <IntroHelpItems />
    </HarbourBaseView>
  );
}
