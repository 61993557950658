import { create } from "zustand";

export const usePirateStore = create((set) => ({
  newItem: null,
  redirectUrl: null,
  installPrompt: null,
  prevRoute: null,
  setNewItem: (newItem) => set({ newItem: newItem }),
  resetNewItem: () => set({ newItem: null }),
  setRedirectUrl: (newUrl) => set({ redirectUrl: newUrl }),
  setInstallPrompt: (event) => set({ installPrompt: event }),
  setPrevRoute: (route) => set({ prevRoute: route }),
}));
