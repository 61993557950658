import React, { useState } from "react";
import log from "../../helper/Logger";
import { BsChevronCompactUp, BsChevronCompactDown } from "react-icons/bs";
import { getDifficulty } from "../../helper/digitToText";
import parse from "html-react-parser";
import text from "../texts/allTexts.json";

const PlaceDetails = ({ place, mapInApp }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [bigger, setBigger] = useState(false);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const handleClick = (e) => {
    e.stopPropagation();
    log.debug(`You clicked on ${place}`);
    setBigger(!bigger);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isDownSwipe) setBigger(false);
    if (isUpSwipe) setBigger(true);
  };

  return (
    <div
      className="flex flex-wrap "
      onClick={handleClick}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div className="w-full flex-col flex items-center text-4xl">
        {bigger ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
      </div>
      <div
        className={
          (mapInApp ? " pt-2 " : " py-2 ") +
          `transition-[height] ease-in-out duration-500 flex flex-col overflow-y-scroll justify-start w-full bg-pirate-bright  ${
            bigger ? "h-[24rem] md:h-[18rem] " : "h-32 md:h-40"
          }`
        }
      >
        <div className="flex flex-row w-full space-x-4">
          <div className="w-2/5 md:w-1/6 overflow-hidden md:pl-2">
            <img src={place?.imageURL} alt="place" />
          </div>
          <div className="w-3/5 md:w-5/6 flex flex-col content-start text-left overflow-y-scroll pr-2 ">
            <div className="text-xl headerFont leading-6 ">{place?.name}</div>
            {place?.coinsProbability === 1 ? (
              <div className="pt-2 text-sm">{text.map.coinsPlace}</div>
            ) : place?.difficulty ? (
              <div className="pt-2 text-sm">
                {text.map.difficulty}: {getDifficulty(place?.difficulty)}
              </div>
            ) : (
              ""
            )}
            {!bigger ? (
              <div className="text-accent  text-sm pt-2 ">
                {text.map.toggleMore}
              </div>
            ) : !mapInApp ? (
              <div className="text-sm pt-4 ">{parse(place?.description)}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        {mapInApp && bigger ? (
          <div className="text-sm w-full p-4 text-left ">
            {parse(place?.description)}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PlaceDetails;
