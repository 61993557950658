import { ScreenWithButton } from "../general/ScreenWithButton";
import TextField from "../general/TextField";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { validateEmail } from "../../helper/mailValidation";
import text from "../texts/allTexts.json";
import { MAX_LENGTH_USER_MAIL } from "../../Settings";
import { NewsletterForm } from "../general/NewsletterForm";
import { useState } from "react";

export function DiplomSetEmail({
  mail = "",
  setMail,
  onClose,
  setStage,
  onClickSend,
  sending,
  levelimg,
  newsletter,
  setNewsletter,
}) {
  const validEmail = validateEmail(mail);
  const [invalidMailError, setInvalidMailError] = useState("");

  return (
    <ScreenWithButton
      buttonText={text.diplom.buttonSend}
      onButtonClick={() => {
        onClickSend();
      }}
      buttonDisabled={!validEmail}
      title={text.diplom.titleSetMail}
      onClose={onClose}
      buttonReplacement={sending ? <WaitingSpinner /> : undefined}
    >
      <div className="h-full">
        {sending ? (
          <div>{text.diplom.textWaiting}</div>
        ) : (
          <>
            <div className="pb-4">{text.diplom.setMail}</div>
            <TextField
              width="w-72"
              value={mail}
              onChange={(v) => setMail(v)}
              onSave={() => {
                if (validEmail || mail === "") {
                  setInvalidMailError("");
                } else {
                  setInvalidMailError(text.error.invalidMail);
                }
              }}
              id="diplom-mail"
              maxLength={MAX_LENGTH_USER_MAIL}
              errorMessage={invalidMailError}
              errorClassname="absolute left-12 -bottom-8 text-accent text-sm"
            />
            <NewsletterForm
              newsletter={newsletter}
              setNewsletter={setNewsletter}
              classAddon="pt-8"
            />
          </>
        )}
        <img
          className="pt-4 max-h-full object-scale-down place-self-center"
          alt="levelup "
          src={levelimg}
        />
      </div>
    </ScreenWithButton>
  );
}
