import { ScreenWithButton } from "../general/ScreenWithButton";
import text from "../texts/allTexts.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IntroHelpItems } from "./IntroHelpItems";

export function IntroHelpScreen({ onClose }) {
  return (
    <ScreenWithButton
      title={text.onboarding.introHelpTitle}
      onButtonClick={onClose}
      buttonText={onClose ? text.onboarding.introHelpButton : ""}
    >
      <IntroHelpItems />
    </ScreenWithButton>
  );
}
