import { Navigate, Outlet } from "react-router-dom";
import { useCode } from "../services/code";
import { usePirateStore } from "../store/usePirateStore";

export function CodeProvider() {
  const code = useCode();
  const setRedirectUrl = usePirateStore((state) => state.setRedirectUrl);
  if (!code && window.location.href.includes("/win/")) {
    const newRedirectUrl = "/win/" + window.location.href.split("/win/").pop();
    console.log("ok setting redirect url to: ", newRedirectUrl);
    setRedirectUrl(newRedirectUrl);
  }
  return code ? <Outlet /> : <Navigate to="/onboarding" />;
}
