import { HarbourBaseView } from "./HarbourBaseView";
import text from "../texts/allTexts.json";
import { useEffect, useState } from "react";
import TitlePlusContent from "../general/TitlePlusContent";

export function HistoryView() {
  const [openAnswer, setOpenAnswer] = useState(1);

  useEffect(() => {
    const element = document.getElementById("title-" + openAnswer);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [openAnswer]);

  return (
    <HarbourBaseView title={text.harbour.history}>
      <TitlePlusContent
        title={text.harbour.historyPart1}
        content={
          <div className="[&>p]:pb-2">
            <p>{text.harbour.historyPart2}</p>
            <p>{text.harbour.historyPart3}</p>
            <p>{text.harbour.historyPart4}</p>
          </div>
        }
        activePart={1}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        title={text.harbour.historyPart5}
        content={
          <div className="[&>p]:pb-2">
            <div className="pb-8 pt-2 flex justify-center">
              <audio
                src="/history/geschichte-kapitel-1.mp3"
                type="audio/mpeg"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <p>{text.harbour.historyPart6}</p>
            <p>{text.harbour.historyPart7}</p>
            <p>{text.harbour.historyPart8}</p>
            <p>{text.harbour.historyPart9}</p>
            <p>{text.harbour.historyPart10}</p>
            <p>{text.harbour.historyPart11}</p>
            <p>{text.harbour.historyPart12}</p>
            <p>{text.harbour.historyPart13}</p>
            <p>{text.harbour.historyPart14}</p>
            <p>{text.harbour.historyPart15}</p>
            <p>{text.harbour.historyPart16}</p>
            <p>{text.harbour.historyPart17}</p>
            <p>{text.harbour.historyPart18}</p>
            <p>{text.harbour.historyPart19}</p>
            <p>{text.harbour.historyPart20}</p>
            <p>{text.harbour.historyPart21}</p>
          </div>
        }
        activePart={2}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        title={text.harbour.historyPart22}
        content={
          <div className="[&>p]:pb-2">
            <div className="pb-8 pt-2 flex justify-center">
              <audio
                src="/history/geschichte-kapitel-2.mp3"
                type="audio/mpeg"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <p>{text.harbour.historyPart23}</p>
            <p>{text.harbour.historyPart24}</p>
            <p>{text.harbour.historyPart25}</p>
            <p>{text.harbour.historyPart26}</p>
            <p>{text.harbour.historyPart27}</p>
            <p>{text.harbour.historyPart28}</p>
            <p>{text.harbour.historyPart29}</p>
            <p>{text.harbour.historyPart30}</p>
            <p>{text.harbour.historyPart31}</p>
            <p>{text.harbour.historyPart32}</p>
            <p>{text.harbour.historyPart33}</p>
            <p>{text.harbour.historyPart34}</p>
          </div>
        }
        activePart={3}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        title={text.harbour.historyPart35}
        content={
          <div className="[&>p]:pb-2">
            <div className="pb-8 pt-2 flex justify-center">
              <audio
                src="/history/geschichte-kapitel-3.mp3"
                type="audio/mpeg"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <p>{text.harbour.historyPart36}</p>
            <p>{text.harbour.historyPart37}</p>
            <p>{text.harbour.historyPart38}</p>
            <p>{text.harbour.historyPart39}</p>
            <p>{text.harbour.historyPart40}</p>
          </div>
        }
        activePart={4}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        title={text.harbour.historyPart41}
        content={
          <div className="[&>p]:pb-2">
            <div className="pb-8 pt-2 flex justify-center">
              <audio
                src="/history/geschichte-kapitel-4.mp3"
                type="audio/mpeg"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <p>{text.harbour.historyPart42}</p>
            <p>{text.harbour.historyPart43}</p>
            <p>{text.harbour.historyPart44}</p>
            <p>{text.harbour.historyPart45}</p>
            <p>{text.harbour.historyPart46}</p>
          </div>
        }
        activePart={5}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
      <TitlePlusContent
        title={text.harbour.historyPart47}
        content={
          <div className="[&>p]:pb-2">
            <div className="pb-8 pt-2 flex justify-center">
              <audio
                src="/history/geschichte-kapitel-5.mp3"
                type="audio/mpeg"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <p>{text.harbour.historyPart48}</p>
            <p>{text.harbour.historyPart49}</p>
          </div>
        }
        activePart={6}
        openAnswer={openAnswer}
        setOpenAnswer={setOpenAnswer}
      />
    </HarbourBaseView>
  );
}
