import { GeneralMapView } from "./GeneralMapView";

// TODO: evtl package wechseln zu https://www.npmjs.com/package/google-maps-react-markers
export function MapOnlyView() {
  return (
    <div className="w-screen h-screen">
      <GeneralMapView mapInApp={false} />
    </div>
  );
}
