import { useLocation, useNavigate } from "react-router-dom";
import { getMyData } from "../../services/code";
import { getMyItems } from "../../services/items";
import { CoinsView } from "../CoinsView";
import { CollectionLevel } from "./CollectionLevel";
import { ScreenWithBar } from "../general/ScreenWithBar";
import { useQuery } from "@tanstack/react-query";
import { ITEMS_LEVEL_1, ITEMS_LEVEL_2, ITEMS_LEVEL_3 } from "../../Settings";
import { Figures } from "./Figures";
import text from "../texts/allTexts.json";
import { usePirateStore } from "../../store/usePirateStore";

export function CollectionView() {
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  const navigate = useNavigate();
  function chooseFigure(figure) {
    setPrevRoute(location);
    navigate("/figure/" + figure.id);
  }

  const coinsView = (
    <CoinsView coins={myData && myData.data ? myData.data.coins : 0} />
  );
  const loading = !myItems.data;
  return (
    <ScreenWithBar
      title={text.main.title}
      rightSide={coinsView}
      fullWidthContent=" -mx-5 "
    >
      <div className="flex flex-col text-left max-h-full ">
        <div>
          <div className="headerFont font-bold text-xl mx-5">
            {text.main.subtitleFigures}
          </div>
          <Figures onClick={chooseFigure} />
        </div>
        <div className="pt-4 flex-grow overflow-y-scroll overflow-x-hidden scrollbar-hide ">
          <div className="headerFont font-bold text-xl mx-5">
            {text.main.subtitleItems}
          </div>
          <CollectionLevel
            level={1}
            activeLevel={myItems.data?.length < ITEMS_LEVEL_1}
            total={ITEMS_LEVEL_1}
            myItems={myItems.data?.slice(0, ITEMS_LEVEL_1)}
            loading={loading}
          />
          <CollectionLevel
            level={2}
            activeLevel={
              myItems.data?.length >= ITEMS_LEVEL_1 &&
              myItems.data?.length < ITEMS_LEVEL_1 + ITEMS_LEVEL_2
            }
            total={ITEMS_LEVEL_2}
            myItems={myItems.data?.slice(
              ITEMS_LEVEL_1,
              ITEMS_LEVEL_1 + ITEMS_LEVEL_2
            )}
            loading={loading}
          />
          <CollectionLevel
            level={3}
            activeLevel={myItems.data?.length >= ITEMS_LEVEL_1 + ITEMS_LEVEL_2}
            total={ITEMS_LEVEL_3}
            myItems={myItems.data?.slice(ITEMS_LEVEL_1 + ITEMS_LEVEL_2)}
            loading={loading}
          />
        </div>
      </div>
    </ScreenWithBar>
  );
}
