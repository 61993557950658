import { ScreenWithBar } from "../general/ScreenWithBar";
import { GeneralMapView } from "./GeneralMapView";

// TODO: evtl package wechseln zu https://www.npmjs.com/package/google-maps-react-markers
export function MapView() {
  return (
    <ScreenWithBar activeTab="map" backgroundStyle="map" animate={false}>
      <img
        src="/map_frame.png"
        className="absolute left-0 -top-3"
        style={{ height: "93%", width: "100%" }}
        alt="map_frame"
      />
      <div className="relative" style={{ height: "95%", width: "100%" }}>
        <GeneralMapView mapInApp={true} />
      </div>
    </ScreenWithBar>
  );
}
