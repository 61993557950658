import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMyData } from "../services/code";
import { getAllItems, getMyItems } from "../services/items";
import { getAllItemsCombinations } from "../services/itemsCombinations";
import { ARItemView } from "./ARItemView";
import { ScreenWithButton } from "./general/ScreenWithButton";
import { CollectionItem } from "./treasures/CollectionItem";
import log from "../helper/Logger";
import { usePirateStore } from "../store/usePirateStore";
import { logCombinedModelAnalyticsEvent } from "../services/firebaseAnalytics";
import text from "../components/texts/allTexts.json";

export const figures = [
  {
    name: "Zaus",
    description: text.figures.zausDescription,
    id: "zaus",
    icon: "zaus/Zaus_0_kl.png",
    addons: {
      hand: {
        name: "Schwert",
        icon: "",
        model: "",
      },
    },
  },
  {
    name: "Huberta",
    description: text.figures.hubertaDescription,
    id: "huberta",
    icon: "huberta/Huberta_0.png",
    addons: {
      hand: {
        name: "Schwert",
        icon: "",
        model: "",
      },
    },
  },
  {
    name: "Du",
    description: text.figures.meDescription,
    id: "me",
    icon: "me/Ich_0.png",
    addons: {
      hand: {
        name: "Schwert",
        icon: "",
        model: "",
      },
    },
  },
];

export function FigureView() {
  const [loading, setLoading] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const items = useQuery({ queryKey: ["items"], queryFn: getAllItems });
  const itemsCombinations = useQuery({
    queryKey: ["itemsCombinations"],
    queryFn: getAllItemsCombinations,
  });
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const resetNewItem = usePirateStore((state) => state.resetNewItem);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  const params = useParams();
  let { figureId } = params;

  const getDefaultModel = () => {
    if (figureId === "zaus") return "models/zaus00_noAssets.glb";
    if (figureId === "huberta") return "models/huberta00_noAssets.glb";
    return null;
  };

  // checks if array contains all elements of target array (by id of element)
  const checker = (arr, target) =>
    target.every((v) => arr.some((x) => v === x));

  const getCombinedModel = (selectedItems) => {
    log.debug("getCombinedModel for: ", selectedItems);
    resetNewItem();
    const combinedModel = itemsCombinations.data
      ?.filter((x) => x.figure === figureId)
      .filter((x) => checker(selectedItems, x.items))
      .filter((x) => x.items.length === selectedItems.length);
    if (combinedModel && combinedModel[0]) {
      log.debug("combinedModel: ", combinedModel);
      logCombinedModelAnalyticsEvent(combinedModel[0].model);
      return combinedModel[0].model;
    } else {
      log.debug("combinedModel not found, fallback to default ");
      return getDefaultModel();
    }
  };

  const [modelToShow, setModelToShow] = useState(() => getDefaultModel());
  const [selectedAssets, setSelectedAssets] = useState([]);

  const figure = figures.reduce((ret, item) => {
    return ret || (item.id === figureId ? item : ret);
  }, undefined);

  const navigate = useNavigate();

  function close() {
    resetNewItem();
    setPrevRoute(location);
    navigate("/");
  }

  const filterMineByFigure = () => {
    return myItems.data?.filter((item) => item.canBeUsedBy?.includes(figureId));
  };

  const getAllApartFromMineForFigure = () => {
    const allApartFromMine = items.data?.reduce((ret, allItem) => {
      return myItems.data?.reduce((ret, myItem) => {
        return ret || myItem.id === allItem.id;
      }, false)
        ? ret
        : [...ret, allItem];
    }, []);
    return allApartFromMine?.filter((item) =>
      item.canBeUsedBy?.includes(figureId)
    );
  };

  const onClickItem = (item) => {
    log.debug("clicked item: ", selectedAssets);
    setLoading(true);
    const newSelectedAssets = selectedAssets.includes(item.id)
      ? selectedAssets.filter((x) => x !== item.id)
      : [...selectedAssets, item.id];
    setSelectedAssets(newSelectedAssets);
    if (newSelectedAssets.length === 0) {
      setModelToShow(getDefaultModel());
    } else {
      setModelToShow(getCombinedModel(newSelectedAssets));
    }
  };

  return (
    <>
      <ScreenWithButton
        title={figure.name}
        subtitle={figure.description}
        subtitlesize={"text-sm"}
        onClose={close}
      >
        <div className="h-full">
          <div className="flex flex-row w-auto place-content-center overflow-x-scroll scrollbar-hide mb-2 gap-1">
            {filterMineByFigure()?.map((item) => {
              return (
                <CollectionItem
                  item={item}
                  key={item?.id}
                  owned={true}
                  onClick={() => {
                    onClickItem(item);
                  }}
                  showIfActive={true}
                  isActive={selectedAssets.includes(item.id)}
                  bigSize={false}
                />
              );
            })}
            {getAllApartFromMineForFigure()?.map((item) => {
              return !myItems.data?.includes((x) => x.id === item.id) ? (
                <CollectionItem
                  key={item?.id}
                  item={item}
                  coins={myData.data?.coins}
                  displayCost={false}
                  bigSize={false}
                />
              ) : (
                ""
              );
            })}
          </div>
          <div
            className="relative flex bg-cover bg-no-repeat bg-center place-content-center place-items-start h-[48vh]"
            style={{
              backgroundImage: "url('/stage_light.png')",
            }}
          >
            {modelToShow ? (
              <ARItemView
                modelURL={modelToShow}
                loading={loading}
                setLoading={setLoading}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                hideOnFullscreen={true}
              />
            ) : (
              <div className="relative h-4/6 w-2/3 flex place-content-center place-items-center">
                {filterMineByFigure()?.length > 0
                  ? text.figures.selectItem
                  : text.figures.noItems}
              </div>
            )}
          </div>
        </div>
      </ScreenWithButton>
      {fullscreen && (
        <ARItemView
          modelURL={modelToShow}
          fullscreen={true}
          setFullscreen={setFullscreen}
          hideOnFullscreen={false}
        />
      )}
    </>
  );
}
