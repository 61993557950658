import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentLevel } from "../../helper/levelsAndDiploma";
import { getMyItems } from "../../services/items";
import { Button } from "../general/Button";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { HarbourBaseView } from "./HarbourBaseView";
import text from "../texts/allTexts.json";
import { Diplom } from "../diplom/Diplom";
import { getMyData } from "../../services/code";
import { usePirateStore } from "../../store/usePirateStore";

export function DiplomView() {
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const level = getCurrentLevel(myItems.data);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  return (
    <HarbourBaseView title={"Dein Diplom"}>
      {level ? (
        <div>
          <Diplom level={level} name={myData.data?.name} />
          <div className="pt-24">
            <Button
              onClick={() => {
                setPrevRoute(location);
                navigate("/leveldone", { state: { startStage: 1 } });
              }}
              wide={true}
            >
              {text.harbour.diplomSendAgain}
            </Button>
          </div>
        </div>
      ) : level === 0 ? (
        <div>
          <div>{text.harbour.diplomNoLevelFinished}</div>
        </div>
      ) : (
        <div className="h-full">
          <div className="mb-4">wird geladen...</div>
          <WaitingSpinner />
        </div>
      )}
    </HarbourBaseView>
  );
}
