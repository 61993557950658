import React from "react";
import { BiPlus } from "react-icons/bi";

export default function TitlePlusContent({
  title,
  content,
  activePart,
  openAnswer,
  setOpenAnswer,
}) {
  return (
    <div id={`title-${activePart}`} className="pb-4 text-left">
      <div
        className=" text-lg"
        onClick={() =>
          setOpenAnswer(openAnswer === activePart ? undefined : activePart)
        }
      >
        {title}
      </div>
      {openAnswer === activePart ? (
        <>
          <div className="text-sm pb-6 pt-2">{content}</div>
        </>
      ) : (
        <BiPlus
          className="mx-2 text-xl text-accent"
          onClick={() =>
            setOpenAnswer(openAnswer === activePart ? undefined : activePart)
          }
        />
      )}
    </div>
  );
}
