import { usePirateStore } from "../../store/usePirateStore";

const itemMapping = {
  Zaus: {
    uteBEPbcDjy2A253kwPV: "/items/zaus/Zaus_cap.png",
    "18kkBoDL64WGLNmWiInp": "/items/zaus/Zaus_schwert.png",
    vFFziovtDVyaYSoCSQs3: "/items/zaus/Zaus_loli.png",
    qQd3RGLDmcwi0SxgkLD6: "/items/zaus/Zaus_augen.png",
  },
  Huberta: {
    uteBEPbcDjy2A253kwPV: "/items/huberta/Huberta_cap.png",
    J8pOqxYQKDEzrQN56Wpy: "/items/huberta/Huberta_pipe.png",
    Xd2EglBCp60J3dhnWTDl: "/items/huberta/Huberta_map.png",
  },
  Du: {
    uteBEPbcDjy2A253kwPV: "/items/me/Ich_cap.png",
    "18kkBoDL64WGLNmWiInp": "/items/me/Ich_schwert.png",
    "9AJ7Sy4dyEAiXJzZqCQW": "/items/me/Ich_fernrohr.png",
  },
};

export function Figure({ onClick, item }) {
  const newItem = usePirateStore((state) => state.newItem);
  // for testing animation
  // const newItem = {
  //   id: "uteBEPbcDjy2A253kwPV",
  //   canBeUsedBy: ["zaus", "huberta", "me"],
  //   difficulty: 2,
  //   icon: "item_icon_piratehat.png",
  //   model: "models/asset_hat.glb",
  //   name: "Piratenhut",
  //   price: 300,
  //   rarity: 1,
  //   superpower: "Mut",
  // };
  const animate = newItem?.canBeUsedBy?.find((x) => x === item.id);
  const imageClassName = animate
    ? "absolute inset-0 max-w-40 h-full animate-pulse"
    : "absolute inset-0 max-w-40 h-full ";
  const newItemImage = itemMapping[item.name]?.[newItem?.id];
  return (
    <div
      onClick={() => {
        onClick(item);
      }}
      className={"w-[40vw] h-[40vw] -mr-6 "}
    >
      <div className="relative py-2 h-full flex place-items-center place-content-center">
        {newItemImage && (
          <img
            src={newItemImage}
            alt="newIconHighlighted"
            className="absolute inset-0 max-w-36 h-full animate-pulse"
          />
        )}
        <img
          src={"/items/" + item.icon}
          alt="itemIcon"
          className={imageClassName}
        />
      </div>
    </div>
  );
}
