import { useState, useEffect } from "react";
import {
  generateRandomCode,
  myCode,
  sendCodeToEmail,
  setCode,
} from "../../services/code";
import { IntroHelpScreen } from "./IntroHelpScreen";
import { ScreenWithButton } from "../general/ScreenWithButton";
import TextField from "../general/TextField";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { validateEmail } from "../../helper/mailValidation";
import text from "../texts/allTexts.json";
import log from "../../helper/Logger";
import { NewsletterForm } from "../general/NewsletterForm";

export function NewCodeView({
  onCodeGenerated,
  onBack,
  codeError,
  setCodeError,
}) {
  const [randomCode, setRandomCode] = useState();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [codeDone, setCodeDone] = useState(false);
  const [sending, setSending] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [invalidMailError, setInvalidMailError] = useState("");

  useEffect(() => {
    async function asy() {
      const existingCode = myCode();
      if (existingCode) {
        setRandomCode(existingCode);
      } else {
        const code = await generateRandomCode();
        setRandomCode(code);
        const success = setCode(code);
        if (!success) {
          setCodeError(true);
        }
      }
    }
    asy();
  }, [setCodeError]);

  async function sendEmail() {
    setSending(true);
    const res = await sendCodeToEmail(email, randomCode, false, newsletter);
    log.debug("result from sendMail: ", res);

    // TODO: show error message if mail not sent
    setEmailSent(true);
    setSending(false);
  }

  const validEmail = validateEmail(email);

  return codeDone && !codeError ? (
    <IntroHelpScreen
      onClose={() => {
        onCodeGenerated(randomCode);
      }}
    />
  ) : (
    <ScreenWithButton
      buttonText={
        emailSent
          ? text.onboarding.newCodeButtonSent
          : validEmail
          ? text.onboarding.newCodeButtonValidMail
          : randomCode && !codeError
          ? text.onboarding.newCodeButtonDone
          : ""
      }
      onButtonClick={() => {
        emailSent
          ? setCodeDone(true)
          : validEmail
          ? sendEmail()
          : setCodeDone(true);
      }}
      title={text.onboarding.newCodeTitle}
      buttonReplacement={sending ? <WaitingSpinner /> : undefined}
      back={true}
      onClose={onBack}
      mode="brightBack"
    >
      {randomCode ? (
        <div className="h-full ">
          <div className="text-accent text-5xl p-6 headerFont">
            {randomCode}
          </div>
          {emailSent ? (
            <div>
              <div className="my-6">{text.onboarding.newCodeTextSent}</div>
              {email}
            </div>
          ) : !codeError ? (
            <div>
              <div className="mt-4">{text.onboarding.newCodeText}</div>
              <div className="mt-6 ">{text.onboarding.newCodeDontLoose}</div>
              <div className="mt-6 mb-2">{text.onboarding.newCodeSendMail}</div>
              <TextField
                value={email}
                onChange={(v) => {
                  setEmail(v);
                  setInvalidMailError("");
                }}
                onSave={(v) => {
                  if (validEmail || email === "") {
                    setInvalidMailError("");
                  } else {
                    setInvalidMailError(text.error.invalidMail);
                  }
                }}
                width="w-72"
                id="code-mail"
                errorMessage={invalidMailError}
                errorClassname="absolute left-12 -bottom-8 text-accent text-sm"
              />
              {email && validEmail && (
                <NewsletterForm
                  newsletter={newsletter}
                  setNewsletter={setNewsletter}
                  classAddon="place-content-center px-4 "
                />
              )}
            </div>
          ) : (
            <div className="p-6 text-2xl">
              {text.onboarding.newCodeCookiesWarning}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="mb-10">{text.onboarding.newCodeWaiting}</div>
          <WaitingSpinner />
        </div>
      )}
    </ScreenWithButton>
  );
}
