export function Link({ classAddon, link, text, nogap = false }) {
  return (
    <a
      className={"text-accent pointer " + classAddon}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {text}
      {nogap ? "" : " "}
    </a>
  );
}
