import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { buyItem, getAllItems, getMyItems } from "../../services/items";
import { CoinsView } from "../CoinsView";
import { ScreenWithButton } from "../general/ScreenWithButton";
import { useQuery } from "@tanstack/react-query";
import { ItemView } from "../ItemView";
import { getMyData } from "../../services/code";
import {
  getCurrentLevel,
  isLevelCompleted,
} from "../../helper/levelsAndDiploma";
import log from "../../helper/Logger";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { usePirateStore } from "../../store/usePirateStore";
import { ListCollectionItem } from "./ListCollectionItem";
import {
  logBuyItemAnalyticsEvent,
  logLevelDoneAnalyticsEvent,
} from "../../services/firebaseAnalytics";
import text from "../texts/allTexts.json";
import Modal from "../general/Modal";
import { Button } from "../general/Button";

export function AllItemsView() {
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const items = useQuery({ queryKey: ["items"], queryFn: getAllItems });
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const [newItem, setNewItem] = useState(undefined);
  const [buyInProgress, setBuyInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const setNewItemToStore = usePirateStore((state) => state.setNewItem);
  const resetNewItem = usePirateStore((state) => state.resetNewItem);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  const navigate = useNavigate();

  const getAllApartFromMine = () => {
    const allApartFromMine = items.data?.reduce((ret, allItem) => {
      return myItems.data?.reduce((ret, myItem) => {
        return ret || myItem.id === allItem.id;
      }, false)
        ? ret
        : [...ret, allItem];
    }, []);
    return allApartFromMine;
  };

  function onClose() {
    resetNewItem();
    const myItemsUpdated = [...myItems.data];
    if (!myItemsUpdated.find((x) => x.id === newItem?.itemId)) {
      if (newItem) {
        const fullNewItem = items.data?.find((x) => x.id === newItem.itemId);
        if (fullNewItem) {
          log.debug("bla fullnewitem: ", fullNewItem);
          myItemsUpdated.push(fullNewItem);
          setNewItemToStore(fullNewItem);
        }
      }
    }
    if (newItem && isLevelCompleted(myItemsUpdated)) {
      logLevelDoneAnalyticsEvent(getCurrentLevel(myItemsUpdated));
      setPrevRoute(location);
      navigate("/leveldone");
    } else {
      setPrevRoute(location);
      navigate("/");
    }
  }

  const onBuyItem = async (itemId, canAfford) => {
    if (!canAfford) {
      setShowModal(true);
      return;
    }

    log.debug("buy item clicked for item with id: ", itemId);
    setBuyInProgress(true);
    const res = await buyItem(itemId);
    log.debug("buy item result: ", res);
    setBuyInProgress(false);
    if (!res) {
      // TODO: show info to user
      log.debug("buy item failed");
      return;
    }
    setNewItem(res);
    const newItemToStore = items.data?.find((x) => x.id === itemId);
    setNewItemToStore(newItemToStore);
    logBuyItemAnalyticsEvent(newItemToStore.name, newItemToStore.price);
  };

  return !items.data?.length && !myItems.data?.length ? (
    <div className="text-center p-10">{text.main.allItemsLoading}</div>
  ) : newItem ? (
    <ItemView
      title={text.main.buyItemSuccessTitle}
      text={text.main.buyItemSuccessText}
      itemId={newItem.itemId}
      onClose={onClose}
    />
  ) : (
    <>
      <ScreenWithButton
        title={[
          text.main.availableItemsTitle1,
          <br />,
          text.main.availableItemsTitle2,
        ]}
        subtitle={text.main.availableItemsText}
        onClose={onClose}
        rightSide={<CoinsView coins={myData.data?.coins} clickable={false} />}
        fullWidthContent=" mb-4 "
      >
        {buyInProgress ? (
          <div className="relative h-96 w-full">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
              <WaitingSpinner />
            </div>
            <div className="text-center p-10">{text.main.buyItem}</div>
          </div>
        ) : (
          <div className="flex flex-wrap h-full place-content-between gap-2 pb-2 ">
            {myItems.data?.map((item) => {
              return (
                <ListCollectionItem item={item} key={item.id} owned={true} />
              );
            })}
            {getAllApartFromMine()?.map((item) => {
              return !myItems.data?.includes((x) => x.id === item.id) ? (
                <ListCollectionItem
                  item={item}
                  onBuy={onBuyItem}
                  key={item.id}
                  coins={myData.data?.coins}
                />
              ) : (
                ""
              );
            })}
          </div>
        )}
      </ScreenWithButton>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="text-base my-10 text-left">
            {text.main.notEnoughCoinsText}
          </div>
          <Button onClick={() => setShowModal(false)}>{"ok"}</Button>
        </Modal>
      )}
    </>
  );
}
