import { BiHide, BiQuestionMark, BiShow } from "react-icons/bi";
import { usePirateStore } from "../../store/usePirateStore";
import { WaitingSpinner } from "../general/WaitingSpinner";

export function CollectionItem({
  onClick,
  item,
  owned,
  loading,
  showIfActive = false,
  isActive,
  activeLevel,
}) {
  const newItem = usePirateStore((state) => state.newItem);
  const animate = newItem?.id === item?.id;

  const format = owned
    ? " p-1 bg-contain "
    : ` bg-contain p-1 text-6xl text-white font-bold `;
  const style = owned
    ? {
        backgroundImage: "url('/collection_item_frame.png')",
        backgroundColor: "#F1E8D877",
        backgroundRepeat: "no-repeat",
      }
    : {
        backgroundImage: "url('/collectionItemDotted.png')",
        backgroundColor: "rgba(0,0,0,0.1)",
        backgroundRepeat: "no-repeat",
      };

  return (
    <div
      onClick={() => {
        onClick && (activeLevel || item) && onClick(item);
      }}
      className={"relative inline h-[20vw] w-[20vw] " + format}
      style={style}
    >
      <>
        {item ? (
          <div className="relative p-2 h-full flex place-items-center place-content-center bg-contain">
            <img
              src={"/items/" + item.icon}
              alt="itemIcon"
              className={
                animate
                  ? " animate-pulse "
                  : owned
                  ? " opacity-100 "
                  : " opacity-40 "
              }
            />
            {showIfActive && (
              <div className="absolute top-0 left-0 text-2xl text-accent">
                {isActive ? <BiShow></BiShow> : <BiHide></BiHide>}
              </div>
            )}
          </div>
        ) : (
          <div className="h-full flex place-content-center place-items-center headerFont">
            {loading ? (
              <WaitingSpinner size={12} />
            ) : activeLevel ? (
              <BiQuestionMark className=" text-lightBrown"></BiQuestionMark>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    </div>
  );
}
