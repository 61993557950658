import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllItems, getMyItems, win } from "../services/items";
import { ItemView } from "./ItemView";
import { ScreenWithButton } from "./general/ScreenWithButton";
import { WaitingSpinner } from "./general/WaitingSpinner";
import { isLevelCompleted } from "../helper/levelsAndDiploma";
import { useQuery } from "@tanstack/react-query";
import log from "../helper/Logger";
import { usePirateStore } from "../store/usePirateStore";
import {
  logLevelDoneAnalyticsEvent,
  logWonCoinsAnalyticsEvent,
  logWonItemAnalyticsEvent,
} from "../services/firebaseAnalytics";
import { getCurrentLevel } from "../helper/levelsAndDiploma";
import text from "../components/texts/allTexts.json";

export function FoundLootView() {
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const items = useQuery({ queryKey: ["items"], queryFn: getAllItems });
  const [wonThis, setWonThis] = useState(undefined);
  const [invalidWincode, setInvalidWincode] = useState(false);
  const setNewItem = usePirateStore((state) => state.setNewItem);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const params = useParams();
  let { placeId } = params;

  useEffect(() => {
    const ratelimitHitItem = {
      type: "ratelimit",
      title: text.main.ratelimitTitle,
      text: text.main.ratelimitText,
      itemId: "models/clock.glb",
    };
    const invalidPlaceIds = ["10uxfDWOKIGm5qJF00"];
    async function fetchMyWin() {
      // reset URL shown in browser to avoid multiple win on page reload
      window.history.replaceState(null, "", "/");

      // wrong QR code scanned, show info to user and return to main view
      if (invalidPlaceIds.some((x) => x === placeId)) {
        setInvalidWincode(true);
        return;
      }

      const wonThis = await win(placeId);
      log.debug("wonThis: ", wonThis);

      if (wonThis && wonThis.res) {
        setWonThis(wonThis.res.result);
      } else {
        // rate limit reached or other problem, show info to user
        if (wonThis && wonThis.reason === "RATELIMIT_HIT") {
          log.debug("rate limit hit");
          setWonThis(ratelimitHitItem);
        } else {
          setInvalidWincode(true);
        }
      }
    }
    fetchMyWin();
  }, [placeId]);

  function onClose() {
    const myItemsUpdated = [...myItems.data];
    if (wonThis && wonThis.type === "asset") {
      if (!myItemsUpdated.find((x) => x.id === wonThis?.itemId)) {
        wonThis.id = wonThis.itemId;
        myItemsUpdated.push(wonThis);
      }
      const newItem = items.data?.find((x) => x.id === wonThis.itemId);
      setNewItem(newItem);
      logWonItemAnalyticsEvent(wonThis.itemName, wonThis.placeName);
    } else if (wonThis && wonThis.type === "coins") {
      setNewItem({ id: "coins" });
      logWonCoinsAnalyticsEvent(wonThis.coins, wonThis.placeName);
    }

    setPrevRoute(location);
    if (
      wonThis &&
      wonThis.type === "asset" &&
      isLevelCompleted(myItemsUpdated)
    ) {
      log.debug("ok leveldone, items: ", myItemsUpdated);
      logLevelDoneAnalyticsEvent(getCurrentLevel(myItemsUpdated));
      navigate("/leveldone");
    } else {
      navigate("/");
    }
  }

  const waitingScreen = invalidWincode ? (
    <ScreenWithButton
      mode="dark"
      title={text.main.winInvalidWincodeTitle}
      onClose={onClose}
    >
      <div className="h-full">
        <div className="p-6 pb-12">{text.main.winInvalidWincodeText}</div>
      </div>
    </ScreenWithButton>
  ) : (
    <ScreenWithButton
      mode="dark"
      title={text.main.winWaitingTitle}
      onClose={onClose}
    >
      <div className="h-full">
        <div className="p-6 pb-12">{text.main.winWaitingText}</div>
        <WaitingSpinner />
      </div>
    </ScreenWithButton>
  );

  return wonThis ? (
    <ItemView
      title={
        wonThis.type === "ratelimit" ? wonThis.title : text.main.wonItemTitle
      }
      text={wonThis.type === "ratelimit" ? wonThis.text : text.main.wonItemText}
      itemId={
        wonThis.type === "asset" || wonThis.type === "ratelimit"
          ? wonThis.itemId
          : undefined
      }
      coins={wonThis.type === "coins" ? wonThis.coins : undefined}
      onClose={onClose}
      icon={wonThis.type === "coins" ? "/app_icons/coins.svg" : undefined}
    />
  ) : (
    waitingScreen
  );
}
