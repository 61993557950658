import React from "react";
import log from "../../helper/Logger";

const MapMarker = ({ place, tooltip, isSelected, onClick, $hover }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    log.debug(`You clicked on ${tooltip}`);
    onClick(place);
  };

  const icon =
    place?.coinsProbability === 1 && isSelected
      ? "marker_coins_selected_.png"
      : place?.coinsProbability === 1 && !isSelected
      ? "marker_coins_.png"
      : isSelected
      ? "marker_default_selected_.png"
      : "marker_default_.png";

  return (
    <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
      <span className="circleText" title={tooltip}>
        <img src={`/app_icons/${icon}`} alt={place.name} className="w-10" />
      </span>
    </div>
  );
};

export default MapMarker;
