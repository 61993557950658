import { useState } from "react";
import { LoginView } from "./LoginView";
import { SplashScreen } from "./SplashScreen";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { PirateCheck } from "./PirateCheckView";
import { NewCodeView } from "./NewCodeView";
import { myCode, setCode } from "../../services/code";
import { useLocation, useNavigate } from "react-router-dom";
import { usePirateStore } from "../../store/usePirateStore";

export function OnboardingView() {
  const [step, setStep] = useState(0);
  const [codeError, setCodeError] = useState(false);
  const navigate = useNavigate();
  const redirectUrl = usePirateStore((state) => state.redirectUrl);
  const setRedirectUrl = usePirateStore((state) => state.setRedirectUrl);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  function setNewCode(newcode) {
    const success = setCode(newcode);
    if (!success) {
      setCodeError(true);
      setStep(2);
      return;
    }
    if (redirectUrl) {
      const navigateTo = redirectUrl.slice();
      setRedirectUrl(null);
      setPrevRoute(location);
      navigate(navigateTo);
    } else {
      setPrevRoute(location);
      navigate("/");
    }
  }

  switch (step) {
    // Welcome Screen
    case 0:
      return (
        <SplashScreen
          onSubmit={() => {
            // redirect if already logged in
            if (myCode() && step === 0) {
              setPrevRoute(location);
              navigate("/");
            } else {
              setStep(1);
            }
          }}
        />
      );
    // Gratz, u won the crate, view in AR
    case 1:
      return (
        <PirateCheck
          onSubmit={(type) => {
            if (type === "new") {
              setStep(2);
            } else {
              setStep(3);
            }
          }}
          onBack={() => setStep(0)}
        />
      );
    case 2:
      return (
        <NewCodeView
          onCodeGenerated={setNewCode}
          onBack={() => {
            setStep(1);
          }}
          codeError={codeError}
          setCodeError={setCodeError}
        />
      );
    // Login with code or generate new
    case 3:
      return <LoginView onCodeEntered={setNewCode} onBack={() => setStep(1)} />;
    // never happens
    default:
      return (
        <div>
          <div className="py-4">Wird geladen... </div>
          <WaitingSpinner />
        </div>
      );
  }
}
