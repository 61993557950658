import { ScreenWithButton } from "../general/ScreenWithButton";
import text from "../texts/allTexts.json";

export function SplashScreen({ onSubmit }) {
  return (
    <ScreenWithButton
      buttonText={text.onboarding.welcomeButton}
      onButtonClick={() => {
        onSubmit();
      }}
      title={text.onboarding.welcome}
      fullWidthContent=" -mx-5"
      mode="brightBack"
    >
      <div className=" h-full">
        <img
          alt="piratenflagge"
          src="/piratenschule_flagge.png"
          className="object-scale-down h-full "
        />
      </div>
    </ScreenWithButton>
  );
}
