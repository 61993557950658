import { execFunction, sendDiplomaFirebaseFunc } from "./firebase";

export async function sendDiplomaToEmail(email, code, level, name, newsletter) {
  return await execFunction(sendDiplomaFirebaseFunc, {
    email,
    code,
    level,
    name,
    newsletter,
  });
}
