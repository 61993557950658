import { ScreenWithButton } from "../general/ScreenWithButton";
import text from "../texts/allTexts.json";

export function DiplomSent({ onClose, mail, levelimg }) {
  return (
    <ScreenWithButton onClose={onClose}>
      <div className="flex flex-col h-full gap-4">
        <div className="headerFont my-4 font-bold text-2xl">
          {text.diplom.sentSuccess}
        </div>
        <div>{text.diplom.sentSuccessTo}</div>
        <div className="pb-8">{mail}</div>
        <img alt="levelup " src={levelimg} />
      </div>
    </ScreenWithButton>
  );
}
