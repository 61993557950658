import { HarbourBaseView } from './HarbourBaseView'

export function FiguresView () {
  return (
    <HarbourBaseView title={'Figuren'}>
      <div className='pb-4'>Zaus ist 90cm gross, trägt immer ein gestreiftes Leibchen und eine Augenklappe, mal links und mal rechts. Er sagt, so könne sich immer ein Auge ausruhen und er sehe mit einem Auge so gut, wie andere mit vier!</div>
      <div className='pb-4'>Huberta sieht ein bisschen aus wie ein Vogel. Und vielleicht ist sie auch tatsächlich einer. Sie gleicht einer Haubentaucherin. Aber das hindert sie nicht daran eine echte Piratin zu sein. Sie kann sprechen! Und sie kann schwimmen.</div>
      <div>Sprechen kann Zaus auch, sogar in sieben Geheimsprachen, die er alleine kennt. Aber schwimmen kann er nicht. Er sagt: „Wer ein Pedalo hat, braucht nicht schwimmen zu können.“ Am Liebsten pedaliert er auf seinem Dreirad über Land. Das Schwimmen und Tauchen überlässt er Huberta.</div>
    </HarbourBaseView>
  )
}
