import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import log from "../helper/Logger";

export function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
    if (analytics) {
      const page_path = location.pathname + location.search;
      logEvent(analytics, "screen_view", {
        firebase_screen: page_path,
      });
    }
  }, [location]);
  return null;
}

export function logCombinedModelAnalyticsEvent(combinedModelName) {
  if (!combinedModelName) return;
  const modelName = combinedModelName.substring(
    combinedModelName.indexOf("/") + 1,
    combinedModelName.indexOf(".")
  );
  log.debug("bla ok log modelcombination selected: ", modelName);
  logEvent(analytics, "select_content", {
    content_type: "combinedModel",
    content_id: modelName,
  });
}

export function logArButtonClickedAnalyticsEvent(modelName) {
  if (!modelName) return;
  const cleanModelName = modelName.substring(
    modelName.indexOf("/") + 1,
    modelName.indexOf(".")
  );
  log.debug("bla ok log ar button clicked: ", cleanModelName);
  logEvent(analytics, "select_content", {
    content_type: "arViewOpened",
    content_id: cleanModelName,
  });
}

export function logWonCoinsAnalyticsEvent(amount, placeName) {
  if (!amount || !placeName) return;
  log.debug("bla ok log coins won: ", amount);
  logEvent(analytics, "win", {
    winType: "coins",
    amount,
    placeName,
  });
}

export function logWonItemAnalyticsEvent(itemName, placeName) {
  if (!itemName || !placeName) return;
  log.debug("bla ok log item won: ", itemName);
  logEvent(analytics, "win", {
    winType: "item",
    itemName,
    placeName,
  });
}

export function logBuyItemAnalyticsEvent(itemName, itemPrice) {
  if (!itemName || !itemPrice) return;
  log.debug("bla ok log item buy: ", itemName);
  logEvent(analytics, "spend_virtual_currency", {
    value: itemPrice,
    virtual_currency_name: "coins",
    item_name: itemName,
  });
}

export function logLevelDoneAnalyticsEvent(level) {
  log.debug("bla should log level for: ", level);
  if (!level) return;
  log.debug("bla ok log level done: ", level);
  logEvent(analytics, "level_up", {
    level,
  });
}
