import text from "../texts/allTexts.json";

export function NewsletterForm({
  classAddon = "col-span-3",
  newsletter,
  setNewsletter,
  onUserinfoChange,
}) {
  return (
    <div
      className={" flex pt-4 text-left place-items-start gap-3 " + classAddon}
    >
      <input
        className=" ml-1 mt-1 bg-lightBrown border-primary text-primary focus:ring-lightBrown "
        type="checkbox"
        checked={newsletter}
        onChange={(e) => {
          setNewsletter(e.target.checked);
          onUserinfoChange && onUserinfoChange(true, e.target.checked);
        }}
      />
      <div className="text-sm mt-1">{text.harbour.newsletter}</div>
    </div>
  );
}
