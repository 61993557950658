import { HarbourBaseView } from "./HarbourBaseView";
import text from "../texts/allTexts.json";
import { Link } from "../general/Link";

export function AboutView() {
  return (
    <HarbourBaseView title={text.harbour.about}>
      <div className="pb-4 text-left [&>*]:pb-2">
        <p>
          Die Piratenschule ist eine Initiative von{" "}
          <Link
            text="Thurgau Tourismus"
            link="https://thurgau-bodensee.ch"
            nogap={true}
          ></Link>
          . Mit der Piratenschule wurde ein Gestaltungsrahmen geschaffen, der es
          ermöglicht, das Freizeit- und Ferienangebot kontinuierlich
          weiterzuentwickeln, die Zusammenarbeit unter den relevanten
          Leistungsträgern zu optimieren und die Wahrnehmung als attraktive,
          familienfreundliche Destination zu stärken.
        </p>
        <p>
          Für Gäste ist die Piratenschule ein Spiel: Bei verschiedenen
          Ausflugszielen warten auf kleine Piraten Aufgaben, mit denen sie die
          Fähigkeiten der echten, grossen Piratinnen erlernen. Als Belohnung
          können mit der Web-App Piratenfiguren, Piratengegenstände und
          Piratenmünzen gesammelt werden. Sind genügend Schätze beisammen,
          erhält man ein Diplom und kommt in die nächste Klasse. Das Produkt
          richtet sich primär an Familien.
        </p>
        <p className="font-bold text-lg pt-2">Beteiligte Unternehmen:</p>
        <p>
          <Link
            text="Thurgau Tourismus"
            link="https://thurgau-bodensee.ch"
          ></Link>
          (Trägerschaft, Umsetzung),{" "}
          <Link text="gutundgut gmbh" link="https://www.gutundgut.ch"></Link>
          (Konzeption, Umsetzung),{" "}
          <Link text="freisicht GmbH" link="https://freisicht.ch"></Link>
          (Web-App),{" "}
          <Link text="Agentur Koch" link="https://www.agenturkoch.ch"></Link>
          (Gestaltung),{" "}
          <Link text="Studio Topo" link="https://www.studiotopo.ch"></Link>
          (Szenographie)
        </p>
        <p className="font-bold text-lg pt-2">Kontakt:</p>
        <p>
          Thurgau Tourismus
          <br />
          Friedrichshafnerstrasse 55a
          <br />
          8590 Romanshorn
          <br />
          <span className="block pt-2">
            Telefon: +41 71 531 01 31
            <br />
            E-Mail:{" "}
            <Link
              text="info@thurgau-bodensee.ch"
              link="mailto:info@thurgau-bodensee.ch"
            ></Link>
          </span>
          <Link
            classAddon="block pt-2"
            text="Datenschutz"
            link="https://thurgau-bodensee.ch/de/datenschutz.html"
          ></Link>
        </p>
        <p className="font-bold text-lg pt-2">Herzlichen Dank:</p>
        <p>
          Der Aufbau der Piratenschule wurde von der{" "}
          <Link text="Thurgauer Kantonalbank" link="https://www.tkb.ch"></Link>
          sowie mit Mitteln der Neuen Regionalpolitik von Bund und Kanton
          Thurgau unterstützt.
        </p>
        <img
          className="pt-4 w-1/2 lg:w-1/4"
          src="/logos/Logo_TKB.svg"
          alt="logo_tkb"
        />
        <img
          className="pt-4 w-1/2 lg:w-1/4"
          src="/logos/Logo_NRP.svg"
          alt="logo_nrp"
        />
      </div>
    </HarbourBaseView>
  );
}
