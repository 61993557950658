export function WaitingSpinner({ size = 40 }) {
  return (
    <div className="spin">
      <div className="spinSquare">
        <img
          src="/app_icons/SelectedIconSchaetze.svg"
          className={"w-" + size + " h-" + size}
          alt="waiting"
        />
      </div>
    </div>
  );
}
