import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getItem } from "../services/items";
import { ARItemView } from "./ARItemView";
import { ScreenWithButton } from "./general/ScreenWithButton";
import { WaitingSpinner } from "./general/WaitingSpinner";
import log from "../helper/Logger";
import { getRarity } from "../helper/digitToText";
import allText from "../components/texts/allTexts.json";

export function ItemView({
  title,
  itemId,
  coins,
  onClose,
  icon,
  text = "",
  hideMetaData = false,
}) {
  const [item, setItem] = useState();
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    async function asy() {
      if (itemId.startsWith("models")) {
        setItem({ model: itemId });
      } else {
        const it = await getItem(itemId);
        it && setItem(it);
      }
    }
    itemId && asy();
  }, []);

  function closeAR() {
    onClose ? onClose() : log.debug("AR closed");
  }

  const metaDataText =
    item && item.price ? (
      <div className="font-bold">
        <div className="flex place-content-center">
          {allText.items.superpower}:{" "}
          <div className="text-accent pl-2">{item.superpower}</div>
        </div>
        <div className="flex place-content-center">
          {allText.items.rarity}:{" "}
          <div className="text-accent pl-2">{getRarity(item.rarity)}</div>
        </div>
        <div className="flex place-content-center">
          {allText.items.price}:{" "}
          <div className="text-accent pl-2"> {item.price}</div>
        </div>
      </div>
    ) : coins ? (
      <div>
        <div className="font-bold">Anzahl: {coins}</div>
      </div>
    ) : undefined;

  return (
    <>
      <ScreenWithButton
        title={title ? title : item ? item.name : "Wird geladen"}
        mode="dark"
        buttonText={icon ? "weiter" : undefined}
        onButtonClick={closeAR}
        onClose={closeAR}
      >
        {!item && !icon ? (
          <div className="h-full">
            <div className="mb-4">wird geladen...</div>
            <WaitingSpinner />
          </div>
        ) : (
          <div className="flex flex-col h-full gap-4">
            <div className="text-base">
              <div>{text}</div>
              {!hideMetaData && (
                <div className="">
                  {title && (
                    <div className="headerFont">
                      {item ? item.name : "Münzen"}
                    </div>
                  )}
                  {metaDataText}
                </div>
              )}
            </div>

            <div
              className="relative flex bg-cover bg-no-repeat bg-center place-content-center place-items-center h-[48vh] "
              style={{
                backgroundImage: "url('/stage_light.png')",
              }}
            >
              {icon ? (
                <img src={icon} className="relative w-2/3" />
              ) : (
                <ARItemView
                  modelURL={item.model}
                  onClose={closeAR}
                  fullscreen={fullscreen}
                  setFullscreen={setFullscreen}
                  hideOnFullscreen={true}
                />
              )}
            </div>
          </div>
        )}
      </ScreenWithButton>
      {fullscreen && (
        <ARItemView
          modelURL={item.model}
          onClose={closeAR}
          fullscreen={true}
          setFullscreen={setFullscreen}
          hideOnFullscreen={false}
        />
      )}
    </>
  );
}
