import { useState } from "react";
import { sendCodeToEmail } from "../../services/code";
import { ScreenWithButton } from "../general/ScreenWithButton";
import TextField from "../general/TextField";
import { WaitingSpinner } from "../general/WaitingSpinner";
import { validateEmail } from "../../helper/mailValidation";
import text from "../texts/allTexts.json";
import log from "../../helper/Logger";

export function ForgotCodeView({ setForgotCode, onBack }) {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [validSent, setValidSent] = useState(true);
  const [invalidMailError, setInvalidMailError] = useState("");

  async function sendEmail() {
    setSending(true);
    const res = await sendCodeToEmail(email, "noCode", true);
    log.debug("result from sendMail: ", res);
    if (res && res.isValid) {
      setValidSent(true);
    } else {
      setValidSent(false);
    }

    setEmailSent(true);
    setSending(false);
  }

  const validEmail = validateEmail(email);

  return (
    <ScreenWithButton
      buttonText={
        emailSent
          ? text.onboarding.forgotCodeButtonNext
          : text.onboarding.forgotCodeButtonSend
      }
      buttonDisabled={!validEmail || !validSent}
      onButtonClick={() => {
        emailSent
          ? setForgotCode(undefined)
          : validEmail
          ? sendEmail()
          : setForgotCode(undefined);
      }}
      title={text.onboarding.forgotCodeTitle}
      buttonReplacement={sending ? <WaitingSpinner /> : undefined}
      back={true}
      onClose={onBack}
      mode="brightBack"
    >
      <div className="h-full ">
        {emailSent ? (
          <div>
            <div className="pt-8 px-8">
              {validSent
                ? text.onboarding.forgotCodeTextSendValid
                : text.onboarding.forgotCodeTextSendInvalid}
            </div>
            {email}
          </div>
        ) : (
          <div>
            <div className="pt-8 px-8">{text.onboarding.forgotCodeText}</div>
            <div className="flex flex-row gap-2 place-content-center py-8">
              <TextField
                value={email}
                onChange={(v) => {
                  setEmail(v);
                  setInvalidMailError("");
                }}
                onSave={(v) => {
                  if (validEmail || email === "") {
                    setInvalidMailError("");
                  } else {
                    setInvalidMailError(text.error.invalidMail);
                  }
                }}
                errorMessage={invalidMailError}
                width="w-72"
              />
            </div>
          </div>
        )}
      </div>
    </ScreenWithButton>
  );
}
