import { useState } from "react";
import { BiEditAlt } from "react-icons/bi";

export default function TextField({
  value,
  onChange,
  onSave,
  classnameAddon,
  styleOnBlur = " border-2 p-2 border-dotted border-primary h-10 ",
  displayIcons = false,
  width = "",
  height = "h-10",
  noEditOnStart,
  id = "textfield",
  editIconClassname = "",
  errorMessage,
  errorClassname = "absolute left-0 -bottom-12 text-accent text-sm",
  maxLength = 256,
}) {
  var classname =
    " border-2 p-2 border-dotted border-primary " + height + " " + width + " ";

  const [editMode, setEditMode] = useState(value === "" && !noEditOnStart);

  const exitEditmode = () => {
    setEditMode(false);
    onSave?.();
  };

  return (
    <div className="relative flex place-content-center place-items-center ">
      <input
        className={
          (editMode ? classname : styleOnBlur + width + " ") + classnameAddon
        }
        type="text"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onBlur={exitEditmode}
        onClick={() => setEditMode(true)}
        id={id}
        maxLength={maxLength}
      />
      {!displayIcons || editMode ? (
        ""
      ) : (
        <div
          className={editIconClassname}
          onClick={() => {
            setEditMode(true);
            document.getElementById(id).focus();
          }}
        >
          <BiEditAlt></BiEditAlt>
        </div>
      )}
      {errorMessage && <div className={errorClassname}>{errorMessage}</div>}
    </div>
  );
}
