import { useLocation, useNavigate } from "react-router-dom";
import { ScreenWithButton } from "../general/ScreenWithButton";
import { usePirateStore } from "../../store/usePirateStore";

export function HarbourBaseView({ title, children }) {
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  function onClose() {
    setPrevRoute(location);
    navigate("/harbour");
  }
  return (
    <ScreenWithButton
      title={title}
      onClose={onClose}
      back="true"
      mode="brightBack"
      fullWidthContent=" mb-4 "
      animationOut="right"
    >
      <div className="h-5/6">{children}</div>
    </ScreenWithButton>
  );
}
