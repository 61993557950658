export function Button({
  onClick,
  children,
  disabled = false,
  classes = " p-2 ",
  wide,
  small
}) {
  // TODO: add wide icon
  const button = wide
    ? "url(/app_icons/wide_button.svg)"
    : small && disabled
    ? "url(/app_icons/small_button_inactive.svg)"    
    : small 
    ? "url(/app_icons/small_button.svg)"    
    : disabled
    ? "url(/app_icons/InactiveButton.svg)"
    : "url(/app_icons/default_button.svg)";
  return (
    <div
      className="p-1"
      style={{
        backgroundImage: button,
        backgroundSize: "100% 100%",
      }}
    >
      <div
        className={
          "text-center text-white cursor-pointer text-lg " + classes
        }
        onClick={onClick && onClick}
      >
        {children}
      </div>
    </div>
  );
}
