import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getMyData } from "../../services/code";
import { sendDiplomaToEmail } from "../../services/diploma";
import { getMyItems } from "../../services/items";
import { ScreenWithButton } from "../general/ScreenWithButton";
import { DiplomSent } from "./DiplomSent";
import { DiplomSetEmail } from "./DiplomSetEmail";
import { DiplomSetName } from "./DiplomSetName";
import log from "../../helper/Logger";
import { getCurrentLevel } from "../../helper/levelsAndDiploma";
import text from "../texts/allTexts.json";
import { setUserCredentials } from "../../services/user";
import { usePirateStore } from "../../store/usePirateStore";

export function LevelFinished() {
  const { state } = useLocation();
  const { startStage } = state || {};
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const queryClient = useQueryClient();
  const [stage, setStage] = useState(0);
  const [username, setUsername] = useState(undefined);
  const [usermail, setUsermail] = useState(undefined);
  const [newsletter, setNewsletter] = useState(true);
  const [sending, setSending] = useState(undefined);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const level = getCurrentLevel(myItems.data);

  const levelimg =
    level === 3
      ? "/level3.png"
      : level === 2
      ? "/level2.png"
      : level === 1
      ? "/level1.png"
      : "/seaanimal.png";

  useEffect(() => {
    if (stage === 0 && myData.data) {
      setUsername(myData.data.name);
      setUsermail(myData.data.email);
      setNewsletter(myData.data.newsletter ? true : false);
      if (startStage > 0) {
        setStage(startStage);
      }
    }
  }, [stage, myData.data, startStage]);

  const onShowDiplom = () => {
    log.debug("showDiplom clicked");
    setUsername(myData.data?.name);
    setStage(1);
  };

  const mutation = useMutation({
    mutationFn: setUserCredentials,
    onSuccess: (res) => {
      log.debug("ok userinfo changed, result: ", res);
      log.debug("username changed to: " + username.toString());
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["myData"] });
    },
  });

  const onSendDiplom = async () => {
    setSending(true);
    const res = await sendDiplomaToEmail(
      usermail,
      myData.data?.code,
      level,
      username,
      newsletter
    );
    const userCode = myData.data?.code;
    mutation.mutate({
      name: username,
      email: usermail,
      code: userCode,
      newsletter: newsletter,
    });
    queryClient.invalidateQueries({ queryKey: ["myData"] });
    log.debug("result from sendMail: ", res);

    setSending(false);
    if (res) {
      setStage(3);
    } else {
      // TODO: replace with styled popup
      alert(text.error.diplomError);
    }
  };

  function onClose() {
    setPrevRoute(location);
    if (level === 3) {
      navigate("/endgame");
    } else {
      navigate("/");
    }
  }

  log.debug("myData ", myData.data);

  return stage === 1 ? (
    <DiplomSetName
      name={username}
      mail={usermail}
      onClickSend={onSendDiplom}
      setName={setUsername}
      level={level}
      onClose={onClose}
      setStage={setStage}
      sending={sending}
      levelimg={levelimg}
    ></DiplomSetName>
  ) : stage === 2 ? (
    <DiplomSetEmail
      mail={usermail}
      setMail={setUsermail}
      onClose={onClose}
      setStage={setStage}
      onClickSend={onSendDiplom}
      sending={sending}
      levelimg={levelimg}
      newsletter={newsletter}
      setNewsletter={setNewsletter}
    ></DiplomSetEmail>
  ) : stage === 3 ? (
    <DiplomSent onClose={onClose} mail={usermail} levelimg={levelimg} />
  ) : level > 0 ? (
    <ScreenWithButton
      title={`${text.diplom.title1} ${level}. ${text.diplom.title2}`}
      mode="dark"
      buttonText={text.diplom.button}
      onButtonClick={onShowDiplom}
      onClose={onClose}
    >
      <img
        alt="ship "
        className="max-h-full object-scale-down place-self-center  "
        src="/ship.png"
      />
    </ScreenWithButton>
  ) : (
    <ScreenWithButton
      title={text.diplom.noLevelFinishedTitle}
      mode="dark"
      buttonText={text.diplom.noLevelFinishedButton}
      onButtonClick={onClose}
      onClose={onClose}
    >
      <img
        alt="ship "
        className=" max-h-full object-scale-down place-self-center "
        src="/ship.png"
      />
    </ScreenWithButton>
  );
}
