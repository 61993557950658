import { motion } from "framer-motion";
import { Button } from "./Button";
import { BiX, BiChevronLeft } from "react-icons/bi";
import { IslandBackgroundView } from "./IslandBackgroundView";
import {
  FADE_TRANSITION,
  FADE_VARIANTS,
  getFadeDirection,
} from "../../helper/PageAnimation";
import { usePirateStore } from "../../store/usePirateStore";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const imageTemplates = {
  bright: {
    bg: "/bg_bright.jpg",
    islands: "/bg_inseln_x_wide.png",
    textColor: "text-primary",
  },
  dark: {
    bg: "/bg_dark.jpg",
    islands: "/bg_inseln_x_dark_wide.png",
    textColor: "text-white",
  },
  brightBack: {
    bg: "/bg_bright.jpg",
    islands: "/bg_inseln_back_wide.png",
    textColor: "text-primary",
  },
};

const variants = {
  left: { opacity: 0 },
  right: { opacity: 0 },
};

export function ScreenWithButton({
  children,
  buttonText,
  onButtonClick,
  buttonDisabled,
  title,
  subtitle,
  subtitlesize,
  onClose,
  mode = "bright",
  rightSide,
  back = false,
  buttonReplacement,
  fullWidthContent,
  animationOut = "bottom",
}) {
  const location = useLocation().pathname;
  const prevRoute = usePirateStore((state) => state.prevRoute);
  const [dynamicOutAnimation, setDynamicOutAnimation] = useState(animationOut);
  const dynamicInAnimation = getFadeDirection(prevRoute, location, "in");

  return (
    <IslandBackgroundView
      style={{ ...imageTemplates[mode], paddingTop: "pt-16" }}
      animationIn={dynamicInAnimation}
      animationOut={dynamicOutAnimation}
    >
      {title && (
        <motion.div
          initial={dynamicInAnimation}
          animate={"default"}
          exit={dynamicOutAnimation}
          variants={FADE_VARIANTS}
          transition={FADE_TRANSITION}
          className="headerFont my-4 font-bold text-3xl px-5"
        >
          {title}
        </motion.div>
      )}
      {subtitle && (
        <motion.div
          initial={dynamicInAnimation}
          animate={"default"}
          exit={dynamicOutAnimation}
          variants={FADE_VARIANTS}
          transition={FADE_TRANSITION}
          className={(back ? " py-4 " : " pb-4 ") + subtitlesize}
        >
          {subtitle}
        </motion.div>
      )}

      <motion.div
        initial={dynamicInAnimation}
        animate={"default"}
        exit={dynamicOutAnimation}
        variants={FADE_VARIANTS}
        transition={FADE_TRANSITION}
        className={
          "flex-grow text-base flex flex-col place-content-center overflow-y-scroll overflow-x-hidden scrollbar-hide " +
          fullWidthContent
        }
      >
        {" "}
        {children}
      </motion.div>

      {buttonText && !buttonReplacement && (
        <div className="flex place-content-center pb-20">
          <Button
            disabled={buttonDisabled}
            onClick={buttonDisabled ? () => {} : onButtonClick}
            classes="w-60 p-2 "
          >
            {buttonText}
          </Button>
        </div>
      )}
      {buttonReplacement && buttonReplacement}

      {onClose && (
        <div
          className={
            "absolute top-2 z-10 font-bold text-6xl" +
            (back ? " left-0 " : " right-0 ")
          }
          onClick={() => {
            onClose();
          }}
        >
          {back ? <BiChevronLeft /> : <BiX />}
        </div>
      )}

      {rightSide && (
        <motion.div
          initial={dynamicInAnimation}
          animate={"default"}
          exit={dynamicOutAnimation}
          variants={FADE_VARIANTS}
          transition={FADE_TRANSITION}
          className="absolute top-16 right-5 z-10 font-bold text-6xl"
        >
          {rightSide}
        </motion.div>
      )}
    </IslandBackgroundView>
  );
}
