import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import text from "../texts/allTexts.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IntroHelpItem } from "./IntroHelpItem";

export function IntroHelpItems() {
  return (
    <div className="h-full py-4">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation]}
        className="mySwiper h-full"
        style={{
          "--swiper-pagination-color": "#D33E62",
          "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-horizontal-gap": "4px",
        }}
      >
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro1.png"
            text={text.harbour.introItem1Text}
          />
        </SwiperSlide>
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro2.png"
            text={text.harbour.introItem2Text}
          />
        </SwiperSlide>
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro3.png"
            text={text.harbour.introItem3Text}
          />
        </SwiperSlide>
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro4.png"
            text={text.harbour.introItem4Text}
          />
        </SwiperSlide>
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro5.png"
            text={text.harbour.introItem5Text}
          />
        </SwiperSlide>{" "}
        <SwiperSlide>
          <IntroHelpItem
            image="/explainimgs/intro6.png"
            text={text.harbour.introItem6Text}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
