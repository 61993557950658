import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { figures } from "../FigureView";
import { Figure } from "./Figure";
import log from "../../helper/Logger";
import { getMyItems } from "../../services/items";
import { getAllItemsCombinations } from "../../services/itemsCombinations";
import { usePirateStore } from "../../store/usePirateStore";

export function Figures({ onClick }) {
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const itemsCombinations = useQuery({
    queryKey: ["itemsCombinations"],
    queryFn: getAllItemsCombinations,
  });
  const [figuresUpdated, setFiguresUpdated] = useState(null);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    // checks if array contains all elements of target array (by id of element)
    const checker = (arr, target) =>
      target.every((v) => arr.some((x) => v === x));

    const getCombinedIcon = (figureId) => {
      const myItemsIds = myItems.data?.map((x) => x.id);
      if (!myItemsIds) return;
      const filteredIcons = itemsCombinations.data
        .filter((x) => x.figure === figureId)
        .filter((x) => checker(myItemsIds, x.items))
        .sort((a, b) => {
          return b.items.length - a.items.length;
        });

      log.debug("filteredIcons: ", filteredIcons);

      if (filteredIcons && filteredIcons[0] && filteredIcons[0].icon) {
        log.debug("combinedIcon: ", filteredIcons[0]);
        return filteredIcons[0].icon;
      } else {
        log.debug("combinedIcon not found, fallback to default ");
        return null;
      }
    };

    if (myItems.data && itemsCombinations.data) {
      let hasSomethingChanged = false;
      const figuresNew = figures.map((x) => {
        const newIcon = getCombinedIcon(x.id);
        if (
          newIcon &&
          figuresUpdated?.find((y) => y.id === x.id)?.icon !== newIcon
        ) {
          log.debug("bla something changed !!");
          hasSomethingChanged = true;
          return { ...x, icon: newIcon };
        } else {
          return x;
        }
      });
      if (hasSomethingChanged || (!figuresUpdated && figuresNew)) {
        log.debug("ok set new figuresUpdated: ", figuresNew);
        setFiguresUpdated(figuresNew);
      }
    }
  }, [myItems.data, itemsCombinations.data]);

  function onItemClick(item) {
    if (onClick) {
      onClick(item);
      return;
    }
    setPrevRoute(location);
    item ? navigate("/item/" + item.id) : navigate("/all_items");
  }

  return (
    <div className="flex flex-row relative -mr-6 -ml-6">
      <div className="overflow-scroll scrollbar-hide flex flex-row place-content-center w-auto flex-1">
        <div className="flex px-4">
          {figuresUpdated?.map((item, i) => {
            return <Figure key={i} onClick={onItemClick} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
}
