import React from "react";
import { BiX } from "react-icons/bi";
import { motion } from "framer-motion";
import { FADE_TIME } from "../../helper/PageAnimation";

export default function Modal({ onClose, children, className }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: FADE_TIME }}
      onClick={() => {
        onClose();
      }}
      className="fixed top-0 left-0 w-full h-full z-50 overflow-scroll"
      style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={
          "bg-lightBrown m-auto w-5/6 md:w-1/2 my-5 relative px-4 py-8 md:p-6 mt-24 " +
          className
        }
      >
        <div
          id="close-modal"
          onClick={() => {
            onClose();
          }}
          className="absolute transition transform hover:scale-105 text-freisicht hover:text-darkGreen text-5xl top-2 right-4 cursor-pointer"
        >
          <BiX></BiX>
        </div>
        {children}
      </div>
    </motion.div>
  );
}
