import { Button } from "../components/general/Button";
import { usePirateStore } from "../store/usePirateStore";

// Taken from: https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
export function AddToHomeScreenButton() {
  const installPrompt = usePirateStore((state) => state.installPrompt);

  const handleAddToHomeScreenClick = () => {
    if (!installPrompt) return;
    installPrompt.prompt();

    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("The app was added to the home screen");
      } else {
        console.log("The app was not added to the home screen");
      }
    });
  };

  return installPrompt ? (
    <Button wide onClick={handleAddToHomeScreenClick}>
      Auf Home Screen speichern
    </Button>
  ) : (
    ""
  );
}

export default AddToHomeScreenButton;
