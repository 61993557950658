import { useLocation, useNavigate } from "react-router-dom";
import { CollectionItem } from "./CollectionItem";
import text from "../texts/allTexts.json";
import { usePirateStore } from "../../store/usePirateStore";

export function CollectionLevel({
  level,
  activeLevel,
  total = 3,
  myItems = [],
  loading = false,
  onClick,
}) {
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  function onItemClick(item) {
    if (onClick) {
      onClick(item);
      return;
    }
    setPrevRoute(location);
    item ? navigate("/item/" + item.id) : navigate("/all_items");
  }

  const items = myItems;
  const somany = total - myItems.length;
  for (var i = 0; i < somany; i++) {
    items.push(undefined);
  }

  return (
    <div className="flex flex-row relative ">
      {level && (
        <div
          className={
            "text-xs headerFont absolute -left-2 bottom-6" +
            (activeLevel ? " text-accent " : "")
          }
        >
          <div className="-rotate-90">
            {level}. {text.main.level}
          </div>
        </div>
      )}

      <div className="overflow-x-scroll scrollbar-hide flex flex-row w-auto flex-1 mb-2 ml-5">
        <div className="flex gap-2 pr-4">
          {items.map((item, i) => {
            return (
              <CollectionItem
                key={i}
                onClick={onItemClick}
                item={item}
                owned={!!item}
                loading={loading}
                activeLevel={activeLevel}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
