import { useEffect, useRef } from "react";
import { useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import GoogleMap from "google-maps-react-markers";
import { getAllPlaces } from "../../services/places";
import MapMarker from "./MapMarker";
import { GOOGLE_MAPS_API_KEY } from "../../Settings";
import PlaceDetails from "./PlaceDetails";
import { mapStyle } from "../../helper/mapStyling";
import MapUserPositionMarker from "./MapUserPositionMarker";
import text from "../texts/allTexts.json";
import log from "../../helper/Logger";

export function GeneralMapView({ mapInApp = false }) {
  const mapRef = useRef(null);
  const [myPlaces, setMyPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(undefined);
  const [userLocation, setUserLocation] = useState(undefined);

  useEffect(() => {
    async function asy() {
      const places = await getAllPlaces();
      places && setMyPlaces(places);
      log.debug("fetched places", places);
      mapInApp && getUserLocation();
    }
    asy();
  }, []);

  const defaultProps = {
    center: {
      lat: 47.580984187969854,
      lng: 9.075714717589193,
    },
    zoom: 11,
  };

  const createMapOptions = function (maps) {
    return {
      styles: mapStyle,
      minZoom: 8,
      disableDefaultUI: true,
    };
  };

  const getUserLocation = () => {
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        const newuserLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(newuserLocation);

        // TODO: set timer to update position
      },
      (error) => {
        log.warn("error getUserLocation, ", error);
        // setUserLocation({
        //   lat: 47.580984187969854,
        //   lng: 9.075714717589193,
        // });

        // permission denied
        if (error.code === 1) {
          // TODO: replace with styled popup message
          const isMobile = /iPhone|iPad|iPod|Android/i.test(
            navigator.userAgent
          );
          if (isMobile) {
            alert(text.map.enableGPS);
          }
        }
      }
    );
  };

  const onPlaceClicked = (place) => {
    log.debug("clicked, place: ", place);
    if (place?.id === selectedPlace?.id) {
      setSelectedPlace(undefined);
    } else {
      setSelectedPlace(place);
    }
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    map.addListener("click", (e) => {
      log.debug("bla new map click event called");
      setSelectedPlace(undefined);
    });
  };

  const onGoToUserLocation = () => {
    log.debug("bla go to userlocation called, ", userLocation);
    if (userLocation) {
      mapRef.current?.setCenter(userLocation);
    }
  };

  return (
    <>
      <GoogleMap
        apiKey={GOOGLE_MAPS_API_KEY}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={() => onPlaceClicked(undefined)}
        options={createMapOptions()}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={(map) => log.debug("Map moved", map)}
      >
        {myPlaces.map((place) => {
          return place.lat ? (
            <MapMarker
              key={place.id}
              lat={place.lat}
              lng={place.lng}
              place={place}
              onClick={onPlaceClicked}
              tooltip={place.name}
              isSelected={selectedPlace?.id === place.id}
            />
          ) : (
            ""
          );
        })}
        {mapInApp && userLocation && (
          <MapUserPositionMarker
            key="userLoc"
            lat={userLocation.lat}
            lng={userLocation.lng}
            text="user"
          ></MapUserPositionMarker>
        )}
      </GoogleMap>
      {mapInApp && userLocation && (
        <BiCurrentLocation
          className="absolute top-4 bottom-auto left-auto right-4 text-2xl"
          onClick={onGoToUserLocation}
        />
      )}
      {selectedPlace && (
        <div className="absolute bottom-0 top-auto left-0 right-0 justify-end items-end  ">
          <PlaceDetails
            place={selectedPlace}
            mapInApp={mapInApp}
          ></PlaceDetails>
        </div>
      )}
    </>
  );
}
