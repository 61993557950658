import { motion } from "framer-motion";
import { FADE_TRANSITION, FADE_VARIANTS } from "../../helper/PageAnimation";

export function IslandBackgroundView({
  children,
  style,
  animationIn = "left",
  animationOut = "right",
}) {
  return (
    <div className="absolute inset-0">
      <div
        className="place-items-center flex flex-col h-full w-full"
        style={{
          backgroundImage: "url(" + style.bg + ")",
          backgroundSize: "100% 100%",
        }}
      >
        <div
          className="flex flex-col bg-slate-200 text-center z-10 bg-no-repeat w-full h-full"
          style={{
            backgroundImage: "url(" + style.bg + ")",
            backgroundSize: "100% 100%",
          }}
        >
          <motion.div
            initial={animationIn}
            animate={"default"}
            exit={animationOut}
            variants={FADE_VARIANTS}
            transition={FADE_TRANSITION}
            className={
              "z-20 flex flex-col h-full py-4 text-center bg-no-repeat pb-0 place-content-center px-5 " +
              style.textColor +
              " " +
              style.paddingTop
            }
            style={{
              backgroundImage: "url(" + style.islands + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {children}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
