import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { getMyData, myCode } from "../../services/code";
import { ScreenWithBar } from "../general/ScreenWithBar";
import TextField from "../general/TextField";
import log from "../../helper/Logger";
import { validateEmail } from "../../helper/mailValidation";
import { setUserCredentials } from "../../services/user";
import AddToHomeScreenButton from "../../helper/AddToHomeScreen";
import text from "../../components/texts/allTexts.json";
import Modal from "../general/Modal";
import { Button } from "../general/Button";
import { MAX_LENGTH_USER_MAIL, MAX_LENGTH_USER_NAME } from "../../Settings";
import { NewsletterForm } from "../general/NewsletterForm";
import { usePirateStore } from "../../store/usePirateStore";
import { getCurrentLevel } from "../../helper/levelsAndDiploma";
import { getMyItems } from "../../services/items";

export function HarbourView() {
  const myData = useQuery({ queryKey: ["myData"], queryFn: getMyData });
  const myItems = useQuery({ queryKey: ["myItems"], queryFn: getMyItems });
  const [username, setUsername] = useState("");
  const [usermail, setUsermail] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [invalidMailError, setInvalidMailError] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const queryClient = useQueryClient();
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  useEffect(() => {
    if (isInitial) {
      if (myData.data) {
        setIsInitial(false);
      }
      if (!username && myData.data?.name) {
        log.debug("bla overwriting username: ", myData.data.name);
        setUsername(myData.data.name);
      }

      if (!usermail && myData.data?.email) {
        setUsermail(myData.data.email);
      }
      if (myData.data?.newsletter) {
        setNewsletter(myData.data?.newsletter);
      }
    }
  }, [myData, username, usermail, isInitial, queryClient]);

  const navigate = useNavigate();
  const links = [
    {
      title: text.harbour.diplom,
      path: "/harbour/diplom",
    },
    {
      title: text.harbour.about,
      path: "/harbour/about",
    },
    {
      title: text.harbour.intro,
      path: "/harbour/intro",
    },
    {
      title: text.harbour.history,
      path: "/harbour/history",
    },
    {
      title: text.harbour.help,
      path: "/harbour/help",
    } /*,
    {
      title: "Regeln",
      path: "/harbour/rules",
    }*/,

    /*     {
      title: "Figuren",
      path: "/harbour/figures",
    }, */
  ];

  const mutation = useMutation({
    mutationFn: setUserCredentials,
    onSuccess: (res) => {
      log.debug("ok userinfo changed, result: ", res);
      log.debug("username changed to: " + username.toString());
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["myData"] });
    },
  });

  const onUserinfoChange = async (checkbox, newNewsletter) => {
    const newsletterValue = checkbox ? newNewsletter : newsletter;
    if (usermail && !validateEmail(usermail)) {
      setInvalidMailError(text.error.invalidMail);
      return;
    }
    if (
      username !== myData.data?.name ||
      usermail !== myData.data?.email ||
      (checkbox && newNewsletter !== myData.data?.newsletter)
    ) {
      const userCode = myCode();
      mutation.mutate({
        name: username,
        email: usermail,
        code: userCode,
        newsletter: newsletterValue,
      });
      queryClient.invalidateQueries({ queryKey: ["myData"] });
    }
  };

  return (
    <>
      <ScreenWithBar
        title={text.harbour.title}
        activeTab="harbour"
        backgroundStyle="harbour"
        animationOut="left"
      >
        <div className="flex flex-col pt-10 text-left">
          <div className="grid grid-cols-4 mb-12 justify-items-start">
            <div className="headerFont">{text.harbour.code}</div>
            <div className="col-span-3">{myCode()}</div>

            <div className="headerFont">{text.harbour.name}</div>
            <div className="col-span-3 w-4/5 ">
              <TextField
                value={username}
                onChange={(v) => setUsername(v)}
                displayIcons={true}
                styleOnBlur=" bg-transparent p-0  "
                classnameAddon=" text-base border-none h-6 m-0 "
                onSave={onUserinfoChange}
                noEditOnStart={true}
                id="user-name"
                width="w-full"
                height="h-6"
                errorMessage={invalidMailError}
                maxLength={MAX_LENGTH_USER_NAME}
              />
            </div>

            <div className="headerFont">{text.harbour.mail}</div>
            <div className="col-span-3 w-4/5 ">
              <TextField
                value={usermail}
                onChange={(v) => {
                  setUsermail(v);
                  setInvalidMailError("");
                }}
                displayIcons={true}
                styleOnBlur=" bg-transparent p-0 "
                classnameAddon=" text-base border-none h-6 m-0 "
                onSave={onUserinfoChange}
                noEditOnStart={true}
                id="user-mail"
                width="w-full"
                height="h-6"
                maxLength={MAX_LENGTH_USER_MAIL}
              />
            </div>
            <NewsletterForm
              classAddon="col-span-4"
              newsletter={newsletter}
              setNewsletter={setNewsletter}
              onUserinfoChange={onUserinfoChange}
            />
          </div>

          {links.map((linkData) => {
            return (
              <div
                className="grid grid-cols-3 headerFont mt-2"
                key={linkData.path}
              >
                <div
                  className="col-span-2 "
                  onClick={() => {
                    setPrevRoute(location);
                    navigate(linkData.path);
                  }}
                >
                  {linkData.title}
                </div>
                <div
                  className="w-fit"
                  onClick={() => {
                    setPrevRoute(location);
                    navigate(linkData.path);
                  }}
                >
                  <BiChevronRight />
                </div>
              </div>
            );
          })}
          <div className="pt-4">
            <AddToHomeScreenButton />
          </div>
          <div
            className="headerFont text-xl mt-12 w-fit"
            onClick={() => setShowLogoutModal(true)}
          >
            {text.harbour.logout}
          </div>
          {getCurrentLevel(myItems.data) === 3 && (
            <div
              className="headerFont text-xl mt-12 w-fit"
              onClick={() => {
                setPrevRoute(location);
                navigate("/endgame");
              }}
            >
              {text.main.wonEverythingTitle}
            </div>
          )}
        </div>
      </ScreenWithBar>
      {showLogoutModal && (
        <Modal onClose={() => setShowLogoutModal(false)}>
          <div className="text-base my-10 text-left">
            {text.harbour.logoutConfirmText}
          </div>
          <Button onClick={logoutConfirm}>
            {text.harbour.logoutConfirmButtonYes}
          </Button>
        </Modal>
      )}
    </>
  );
}

const logoutConfirm = () => {
  localStorage.removeItem("code");
  window.location.href = "/";
};
