import { Link } from "../general/Link";

export function Diplom({ level = 0, name = "" }) {
  return (
    <div className="flex relative justify-center">
      <div
        className={`relative w-[80vw] aspect-[0.707] text-[#3f3431]`}
        style={{
          fontFamily: "AkzidenzAlt, sans-serif",
        }}
      >
        {/* <!-- Header --> */}
        <div className="flex flex-col">
          <div className="flex justify-center py-[4vw]">
            <img
              className="w-[14.4vw]"
              src="/diplom/piratenschule_label.png"
              alt="label"
            />
          </div>
          <div className="flex justify-center pb-[1.6vw]">
            <img
              className="my-auto h-[0.3vw] w-auto"
              src="/diplom/Icons_Punkte-Lang.svg"
              alt="dots"
            />
          </div>
        </div>
        {/* <!-- Main --> */}
        <div className="flex flex-col p-[1.6vw]">
          <div className="justify-center items-center">
            <p
              className="leading-none text-center text-[11.2vw] m-[0.4vw] px-[8vw]"
              style={{
                fontFamily: "RetjehAlt, serif",
              }}
            >
              PIRATEN-DIPLOM
            </p>
            <div className="flex justify-center items-center align-middle h-[2.4vw]">
              <img
                className="w-auto h-[0.3vw]"
                src="/diplom/Icons_Punkte-Kurz.svg"
                alt="dots"
              />
              <p
                style={{
                  textAlign: "center",
                  marginLeft: "0.4vw",
                  marginRight: "0.4vw",
                  marginTop: "0.24vw",
                  fontFamily: "RetjehAlt, serif",
                  fontSize: "1.6vw",
                }}
              >
                {" "}
                V O N{" "}
              </p>
              <img
                className="w-auto h-[0.33vw]"
                src="/diplom/Icons_Punkte-Kurz.svg"
                alt="dots"
              />
            </div>
            <p
              style={{
                textAlign: "center",
                paddingTop: "0.5vw",
                margin: "0.4vw",
                fontFamily: "Bellfort, serif",
                fontSize: "4vw",
              }}
            >
              {name}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1 flex flex-col items-center justify-center">
              <p
                className=""
                style={{
                  textAlign: "center",
                  margin: "0.4vw",
                  fontFamily: "RetjehAlt, sans-serif",
                  fontSize: "1.12vw",
                  lineHeight: "1.36vw",
                }}
              >
                Gratulation! Du hast schon
                <br />
                viele Aufgaben gelöst und <br />
                Piratengegenstände für deine <br />
                Schatztruhe gesammelt. <br />
                Weil du so fleissig bist, hast <br />
                du schon Einiges gelernt.
              </p>
            </div>
            <div>
              <img
                src="/diplom/Zaus_Huberta_Piratenschiff_DB.png"
                alt="pirateship"
              />
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center -z-20">
              <p
                style={{
                  textAlign: "center",
                  margin: "0.4vw",
                  fontFamily: "RetjehAlt, sans-serif",
                  fontSize: "1.12vw",
                  lineHeight: "1.36vw",
                }}
              >
                Gerne bestätigen wir dir,
                <br />
                dass du die
                <span className="text-[#ebe2c9] relative pb-[0.24vw] pl-[0.32vw] pr-[0.24vw]">
                  {` ${level}. Klasse `}
                  <img
                    className="absolute bottom-0 left-0 -z-10 p-[0.16vw]"
                    src="/diplom/Icons_Hintergrund-Klasse.svg"
                    alt="classBg"
                  />
                </span>
                erfolgreich
                <br />
                bestanden hast. Weiter so!
                <br />
                Viel Spass beim Weiterrätseln und
                <br />
                Entdecken. Ahoi!
              </p>
              <p
                className="text-[2.16vw] text-center m-[0.8vw]"
                style={{
                  fontFamily: "Bellfort, serif",
                }}
              >
                Zaus & Huberta
              </p>
            </div>
          </div>
        </div>
        {/* <!-- Footer --> */}
        <div className="flex flex-col pt-[4vw]">
          <div className="flex justify-center pb-4">
            <img
              className="w-[14.4vw]"
              src="/diplom/thurgau-bodensee-logo.svg"
              alt="tgt logo"
            />
          </div>
          <div className="flex justify-center items-center align-middle h-[2.4vw]">
            <img
              className="w-auto h-[0.3vw]"
              src="/diplom/Icons_Punkte-Kurz.svg"
              alt="dots"
            />
            <p className="text-[0.96vw] text-center mt-[0.08vw] mx-[0.56vw]">
              {" "}
              Zeige dieses Diplom in der Tourismus-Infostelle in Kreuzlingen
              (Seestrasse 45,{" "}
              <Link
                text="thurgau-bodensee.ch/infostellen"
                link="https://thurgau-bodensee.ch/infostellen"
                nogap={true}
              ></Link>
              ) vor und{" "}
            </p>
            <img
              className="w-auto h-[0.41vw]"
              src="/diplom/Icons_Punkte-Kurz.svg"
              alt="dots"
            />
          </div>
          <div className="flex justify-center">
            <p className="text-[0.96vw] text-center m-0 leading-none">
              du erhältst von den Landratten dort eine kleine Überraschung zur
              Belohnung.
            </p>
          </div>
        </div>
        <img
          className="absolute w-full aspect-[0.707] -z-40 top-0 left-0"
          src="/diplom/piratenschule_background_hell.jpg"
          alt="bg"
        />
      </div>
    </div>
  );
}
