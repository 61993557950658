import { resetMyData } from "./code";
import { execFunction, setUserCredentialsFirebaseFunc } from "./firebase";

export async function setUserCredentials({ name, email, code, newsletter }) {
  resetMyData();
  return await execFunction(setUserCredentialsFirebaseFunc, {
    name,
    email,
    code,
    newsletter,
  });
}
