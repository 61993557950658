import text from "../components/texts/allTexts.json";

export const getRarity = (rarity) => {
  const rarityText =
    rarity === 1
      ? text.items.rarity1
      : rarity === 2
      ? text.items.rarity2
      : rarity === 3
      ? text.items.rarity3
      : rarity === 4
      ? text.items.rarity4
      : "";
  return rarityText;
};

export const getDifficulty = (difficulty) => {
  const difficultyText =
    difficulty === 1
      ? text.items.difficulty1
      : difficulty === 2
      ? text.items.difficulty2
      : difficulty === 3
      ? text.items.difficulty3
      : "";
  return difficultyText;
};
