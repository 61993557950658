import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePirateStore } from "../../store/usePirateStore";
import { ScreenWithButton } from "../general/ScreenWithButton";
import text from "../texts/allTexts.json";
import "./WonEverything.css";

export function WonEverything() {
  const prevRoute = usePirateStore((state) => state.prevRoute);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  function onClose() {
    setPrevRoute(location);
    navigate("/harbour");
  }

  return (
    <ScreenWithButton
      title={text.main.wonEverythingTitle}
      mode="dark"
      buttonText={"weiter"}
      onButtonClick={onClose}
      onClose={onClose}
      animationOut={prevRoute === "/harbour" ? "right" : "bottom"}
      back={prevRoute === "/harbour"}
    >
      <div className="flex flex-col text-left">
        <div className="pyro">
          <div className="before"></div>
          <div className="after"></div>
        </div>
        <img src="endgame.png" alt="dragon" className="mb-10 self-center" />
        <div>{text.main.wonEverythingText}</div>
      </div>
    </ScreenWithButton>
  );
}
