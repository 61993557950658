export const FADE_TIME = 0.2;

// const transition = {
//   x: { type: "spring", stiffness: 300, damping: 50 },
//   opacity: { duration: FADE_TIME },
// };

// const transition = {
//   x: { type: "spring", stiffness: 100, damping: 30 },
//   duration: FADE_TIME,
// };

export const FADE_TRANSITION = {
  ease: "easeInOut",
  duration: FADE_TIME,
  bounce: 0,
};

// TODO: make slide animation
export const FADE_VARIANTS = {
  left: {
    x: "-100vw",
    opacity: 0,
  },
  right: {
    x: "100vw",
    opacity: 0,
  },
  top: {
    y: "-100vw",
    opacity: 0,
  },
  bottom: {
    y: "100vw",
    opacity: 0,
  },
  default: { zIndex: 1, x: "0", y: "0", opacity: 1 },
  fadeout: { x: "0", y: "0", opacity: 0 },
};

export const getFadeDirection = (prev, next, inOrOut) => {
  // map is always right
  if (
    (prev === "/" || prev === "/coll" || prev === "/harbour") &&
    next === "/map"
  ) {
    return inOrOut === "in" ? "right" : "left";
  }
  // right if harbour to coll
  if (prev === "/harbour" && (next === "/" || next === "/coll")) {
    return inOrOut === "in" ? "right" : "left";
  }

  // harbour is always left
  if (
    (prev === "/" || prev === "/coll" || prev === "/map") &&
    next === "/harbour"
  ) {
    return inOrOut === "in" ? "left" : "right";
  }

  // left if map to coll
  if (prev === "/map" && (next === "/" || next === "/coll")) {
    return inOrOut === "in" ? "left" : "right";
  }

  // return to coll
  if (next === "/" || next === "/coll") {
    return inOrOut === "in" ? "fadeout" : "bottom";
  }

  // return to harbour
  if (next === "/harbour") {
    return inOrOut === "in" ? "left" : "right";
  }

  // from harbour
  if (prev === "/harbour") {
    return inOrOut === "in" ? "right" : "left";
  }

  // onboarding
  if (next === "/onboarding") {
    return inOrOut === "in" ? "fadeout" : "fadeout";
  }

  return "bottom";
};
