export function IntroHelpItem({ image, text }) {
  return (
    <div className="h-full flex flex-col items-center">
      <img
        src={image}
        alt="itemIcon"
        className="pb-4 h-2/4 object-scale-down "
      />
      <div className="object-fill w-full">{text}</div>
    </div>
  );
}
