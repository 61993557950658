import { ITEMS_LEVEL_1, ITEMS_LEVEL_2, ITEMS_LEVEL_3 } from "../Settings";

export const isLevelCompleted = (myItems) => {
  // check if level just completed
  // TODO: state problem, items data not updated yet with new item
  return (
    myItems?.length === ITEMS_LEVEL_1 ||
    myItems?.length === ITEMS_LEVEL_1 + ITEMS_LEVEL_2 ||
    myItems?.length === ITEMS_LEVEL_1 + ITEMS_LEVEL_2 + ITEMS_LEVEL_3
  );
};

export const getCurrentLevel = (myItems) => {
  const level =
    myItems?.length >= ITEMS_LEVEL_1 + ITEMS_LEVEL_2 + ITEMS_LEVEL_3
      ? 3
      : myItems?.length >= ITEMS_LEVEL_1 + ITEMS_LEVEL_2
      ? 2
      : myItems?.length >= ITEMS_LEVEL_1
      ? 1
      : 0;
  return level;
};
