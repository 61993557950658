import {
  checkCodeFirebaseFunc,
  execFunction,
  generateCodeFirebaseFunc,
  getMyDataFirebaseFunc,
  sendCodeFirebaseFunc,
} from "./firebase";
import log from "../helper/Logger";

let code = "";
let myData = undefined;

try {
  code = localStorage.getItem("code");
} catch (error) {
  log.debug("error no local storage access");
}

export function setCode(c) {
  try {
    localStorage.setItem("code", c);
  } catch (error) {
    log.debug("error in setting localStorage! ");
    code = c;
    return false;
  }

  code = c;
  return true;
}

export function useCode() {
  return code;
}

export function myCode() {
  return code;
}

export async function getMyData() {
  if (!myData) {
    log.debug("bla getMyData called");
    myData = await execFunction(getMyDataFirebaseFunc, { code });
  }
  return myData;
}

export function resetMyData() {
  log.debug("bla reset myData called");
  myData = undefined;
}

export async function generateRandomCode() {
  return await execFunction(generateCodeFirebaseFunc, {});
}

export async function sendCodeToEmail(email, code, forgotCode, newsletter) {
  return await execFunction(sendCodeFirebaseFunc, {
    email,
    code,
    forgotCode,
    newsletter,
  });
}

export async function checkCode(code) {
  return await execFunction(checkCodeFirebaseFunc, { code });
}
