import React, { useState } from "react";
import { useEffect } from "react";
import "@google/model-viewer";
import { Button } from "./general/Button";
import { WaitingSpinner } from "./general/WaitingSpinner";
import log from "../helper/Logger";
import { BiX } from "react-icons/bi";
import { logArButtonClickedAnalyticsEvent } from "../services/firebaseAnalytics";
import text from "../components/texts/allTexts.json";

export function ARItemView({
  modelURL,
  loading,
  setLoading,
  fullscreen,
  setFullscreen,
  hideOnFullscreen,
}) {
  const modelRef = React.useRef();
  const [fullscreenButton, setFullscreenButton] = useState(false);

  useEffect(() => {
    const modelViewer = document.querySelector("model-viewer");
    modelViewer?.addEventListener("load", function (evt) {
      log.debug("ok model loaded");
      setLoading?.(false);
    });

    // cleanup this component
    // return () => {
    //   window.removeEventListener('keydown', handleKeyDown);
    // };
  }, [setLoading]);

  useEffect(() => {
    const modelViewer = document.querySelector("model-viewer");
    modelViewer?.addEventListener("load", () => {
      log.debug("model-viewer can activate AR: " + modelViewer.canActivateAR);
      if (!modelViewer.canActivateAR) {
        log.debug("show option to make bigger");
        setFullscreenButton(true);
      } else {
        const arButton = document.getElementById("ar-button");
        if (arButton) {
          arButton.addEventListener("click", () =>
            logArButtonClickedAnalyticsEvent(modelURL)
          );
        }
      }
    });
  });

  return (
    <div
      className={` ${
        fullscreen && hideOnFullscreen
          ? "invisible"
          : fullscreen
          ? "fixed top-0 left-0 w-full h-full z-50"
          : "relative h-5/6 w-full"
      }`}
      style={fullscreen ? { backgroundColor: "rgba(0,0,0,0.8)" } : {}}
    >
      {loading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
          <WaitingSpinner />
        </div>
      )}
      {fullscreen && (
        <div
          id="close-modal"
          onClick={() => {
            setFullscreen(false);
          }}
          className="absolute text-6xl top-4 right-0 font-bold text-white z-30"
        >
          <BiX />
        </div>
      )}
      <model-viewer
        id="piraten-model-viewer"
        style={{
          width: "100%",
          height: "100%",
          visibility: fullscreen && hideOnFullscreen ? "hidden" : "visible",
        }}
        className="w-full h-full"
        src={"/" + modelURL}
        alt="Piratenschule Asset"
        exposure="1"
        camera-controls
        ar
        ar-scale="auto"
        ar-modes="scene-viewer quick-look webxr"
        touch-action="pan-y"
        ref={modelRef}
      >
        <button
          slot="ar-button"
          id="ar-button"
          className="absolute bottom-0 left-1/2 -ml-20 bg-ora "
        >
          <Button classes=" p-6 ">{text.itemView.placeButton}</Button>
        </button>
        <button slot="exit-webxr-ar-button">
          <div className="absolute left-0 top-4 right-0 font-bold text-6xl">
            <BiX />
          </div>
        </button>
        {/*<div
          className='absolute left-0 top-5 right-0 flex place-content-center'
          onClick={() => {
            doScreenShot()
          }}
        >
          <img src='/screenshot_icon.png' className='w-20' alt='screenshot' />
        </div>*/}
      </model-viewer>
      {fullscreenButton && (
        <Button onClick={() => setFullscreen(true)}>
          {text.itemView.scaleButton}
        </Button>
      )}
    </div>
  );
}
